import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-subscriber-receipt-ledger-print',
  templateUrl: './show-subscriber-receipt-ledger-print.component.html',
  styleUrls: ['./show-subscriber-receipt-ledger-print.component.scss'],
})
export class ShowSubscriberReceiptLedgerPrintComponent implements OnInit {
  data = [];
  total_amt;
  total_discount;
  constructor(
    private dialogref: MatDialogRef<ShowSubscriberReceiptLedgerPrintComponent>,
    @Inject(MAT_DIALOG_DATA) public res: any
  ) {}

  ngOnInit(): void {
    this.data = this.res['val'];
    // console.log(this.data);
    this.calculateTotal();
  }

  calculateTotal() {
    this.total_amt = 0;
    this.total_discount = 0;
    for (let index = 0; index < Object.keys(this.data).length; index++) {
      this.total_amt += parseFloat(this.data[index]['total_amt']);
      this.total_discount += parseFloat(this.data[index]['discount']);
    }
  }

  print() {
    window.print();
  }
}
