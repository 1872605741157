<ng-container *ngIf="!isLoading">
    <div>
        <div style="margin-left: 80%;  margin-bottom: 2%;">
            <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary" [useExistingCss]="true"
                printSectionId="print-section" ngxPrint>
                print
            </button>
            <!-- <button class="noPrint" style="margin-top: 2%; margin-left: 2%" (click)="saveAdvtInvoice()" mat-raised-button
            color="primary">
            Save
        </button> -->
            <button class="noPrint" style="margin-top: 2%" mat-button mat-dialog-close>
                Close
            </button>
        </div>
        <div id="print-section">
            <ng-container *ngFor="let showData of filteredShowData; let i=index">
                <div [ngStyle]="{'margin-top':i != 0 ? '45px' : '0px' }">
                    <div class="container" style="margin: 0 15px;">
                        <div class="row botm height">
                            <div class="col-9 topheader bottom right">
                                <span class="fontBold">हिंदुस्थान प्रकाशन संस्था</span>
                                <span><span class="heading1">प्रशासकीय कार्यालय</span> - </span>
                                <span>३०३, वडाळा उद्योग भवन, नायगाव क्रॉस रोड, वडाळा, मुंबई ४०० ०३१</span>
                                <span>दूरध्वनी: ९९६७५७०५३१, Email:
                                    vivekwkladvt&#64;gmail.com</span>
                            </div>
                            <div class="col-3 bottom flex">
                                <img src="../../../assets/img/brand/red.png" alt="">
                            </div>
                        </div>
                        <div class="row taxheading bottom">
                            <div class="fontBold">
                                Tax Invoice / जाहिरात देयक
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 bottom right heading1">
                                Our GST No: 27AAATH0433Q1ZI
                            </div>
                            <div class="col-6 bottom heading1">
                                Advt Invoice No: {{ showData['invoice_no'] }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 right bottom heading1">
                                Our PAN No: AAATH0433Q
                            </div>
                            <div class="col-6 bottom heading1">
                                Advt Invoice Date: {{ showData['invoice_date'] }}
                            </div>
                        </div>
                        <div class="row botm height1">
                            <div class="col-7 bottom right flexbox">
                                <span class="heading1 alignment">Name: {{ showData['advt_name'] }}<span></span></span>
                                <span class="alignment">Address: {{ showData['address'] }}<span></span></span>
                                <span>State: {{ showData['state'] }}</span>
                                <span>Customer GST No: {{ showData['cust_gst_no'] }}</span>
                            </div>
                            <div class="col-5 bottom flexbox addpad" style="padding: 0px;">
                                <span class="flexbox bottom">
                                    <span>Ro No.: {{ showData['ro_no'] }} </span>

                                 <span> RO date : {{ showData['ro_date'] }}</span>
                                </span>
                                <span class="">
                                    <span>Client Name: {{ showData['cust_name'] }}</span>
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 bottom">माहे {{ transform(showData['invoice_date'])}}, विवेक
                                साप्तहिक या
                                प्रकाशनामध्ये पुढील
                                तपशिलानुसार जाहिरात
                                प्रसिद्ध
                                करण्यात आली,
                                धन्यवाद!</div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">आठवडे -</div>
                            <div class="col right bottom cent">1) पहिला</div>
                            <div class="col right bottom cent">2) दुसरा</div>
                            <div class="col right bottom cent">3) तिसरा</div>
                            <div class="col right bottom cent">4) चौथा</div>
                            <div class="col right bottom cent">5) पाचवा</div>
                            <div class="col bottom cent">रक्कम रुपये</div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">अंक दिनांक :</div>
                            <div class="col right bottom cent">{{ showData['firstweek']['issue_date'] }}</div>
                            <div class="col right bottom cent">{{ showData['secondweek']['issue_date'] }}</div>
                            <div class="col right bottom cent">{{ showData['thirdweek']['issue_date'] }}</div>
                            <div class="col right bottom cent">{{ showData['fourthweek']['issue_date'] }}</div>
                            <div class="col right bottom cent">{{ showData['fifthweek']['issue_date'] }}</div>
                            <div class="col bottom"></div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">जाहिरात आकार :</div>
                            <div class="col right bottom cent">{{ showData['firstweek']['advt_size'] }}</div>
                            <div class="col right bottom cent">{{ showData['secondweek']['advt_size'] }}</div>
                            <div class="col right bottom cent">{{ showData['thirdweek']['advt_size'] }}</div>
                            <div class="col right bottom cent">{{ showData['fourthweek']['advt_size'] }}</div>
                            <div class="col right bottom cent">{{ showData['fifthweek']['advt_size'] }}</div>
                            <div class="col bottom"></div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">पृष्ठ क्र. :</div>
                            <div class="col right bottom cent">{{ showData['firstweek']['page_no'] }}</div>
                            <div class="col right bottom cent">{{ showData['secondweek']['page_no'] }}</div>
                            <div class="col right bottom cent">{{ showData['thirdweek']['page_no'] }}</div>
                            <div class="col right bottom cent">{{ showData['fourthweek']['page_no'] }}</div>
                            <div class="col right bottom cent">{{ showData['fifthweek']['page_no'] }}</div>
                            <div class="col bottom cent"></div>
                        </div>
                        <div class="row botm">
                            <div class="col right bottom">HSN / SAC :</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['hsn'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['hsn'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['hsn'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['hsn'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['hsn'] }}</div>
                            <div class="col bottom rightside"></div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">जाहिरात दर :</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['total'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['total'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['total'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['total'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['total'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['total'] }}</div>
                        </div>
                        <!-- <div class="row">
                            <div class="col right bottom">सूट रु :</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['dis'] }} </div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['dis'] }} </div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['dis'] }} </div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['dis'] }} </div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['dis'] }} </div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['dis'] }} </div>
                        </div> -->
                        <div class="row">
                            <div class="col right bottom">सूट रु :</div>
                            <!-- <div class="col right bottom rightside">{{ showData['firstweek']['dis'] }} </div> -->
                            <div class="col right bottom rightside">{{ calculateDiscount(showData['firstweek']['dis'],showData['firstweek']['total'] )}} </div> 
                            <div class="col right bottom rightside">{{ calculateDiscount(showData['secondweek']['dis'],showData['secondweek']['total'] ) }} </div>
                            <div class="col right bottom rightside">{{ calculateDiscount(showData['thirdweek']['dis'],showData['thirdweek']['total'] )}} </div>
                            <div class="col right bottom rightside">{{ calculateDiscount(showData['fourthweek']['dis'],showData['fourthweek']['total'] ) }} </div>
                            <div class="col right bottom rightside">{{ calculateDiscount(showData['fifthweek']['dis'],showData['fifthweek']['total'] )}} </div>
                            <div class="col bottom rightside">{{ calculateDiscount(showData['firstweek']['dis'],showData['firstweek']['total'] ) + calculateDiscount(showData['secondweek']['dis'],showData['secondweek']['total'] ) + calculateDiscount(showData['thirdweek']['dis'],showData['thirdweek']['total'] ) +  calculateDiscount(showData['fourthweek']['dis'],showData['fourthweek']['total'] ) +  calculateDiscount(showData['fifthweek']['dis'],showData['fifthweek']['total'] )}} </div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">निव्वळ रु :</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['net'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['net'] }}</div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">GST अगोदर</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['advt_rate'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['advt_rate'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['advt_rate'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['advt_rate'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['advt_rate'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['advt_rate'] }}</div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">2.5 % CGST</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['cgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['cgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['cgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['cgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['cgst'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['cgst'] }}</div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">2.5 % SGST</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['sgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['sgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['sgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['sgst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['sgst'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['sgst'] }}</div>
                        </div>
                        <div class="row">
                            <div class="col right bottom">5.0 % IGST</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['igst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['igst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['igst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['igst'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['igst'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['igst'] }}</div>
                        </div>
                        <div class="row botm">
                            <div class="col right bottom">Round Off.</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['net'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['net'] }}</div>
                        </div>
                        <div class="row botm">
                            <div class="col right bottom heading1">Total</div>
                            <div class="col right bottom rightside">{{ showData['firstweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['secondweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['thirdweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fourthweek']['net'] }}</div>
                            <div class="col right bottom rightside">{{ showData['fifthweek']['net'] }}</div>
                            <div class="col bottom rightside">{{ this.showData['total_amt']['net'] }}</div>
                        </div>
                        <div class="row">
                            <div class="col-12 bottom">Invoice Total ( In Words) : {{ showData['total_in_words'] }}</div>
                        </div>
                        <div class="row">
                            <div class="col-12 bottom"></div>
                        </div>
                        <div class="row">
                            <div class="col-8 right bottom heading1">
                                Bank Details -
                            </div>
                            <div class="col-4 bottom spacebet"><span>मागील बाकी रु. :</span><span>{{ showData['pre_bal']
                                    }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 right bottom">
                                A/C Name - HINDUSTAN PRAKASHAN SANSTHA
                            </div>
                            <div class="col-4 bottom spacebet"><span>देयक रक्कम :</span><span>{{this.showData['total_amt']['net']
                                    }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 right bottom">A/C No. - 20109800154</div>
                            <div class="col-4 bottom spacebet"><span>एकूण येणे रु. :</span><span>{{
                                this.showData['total_amt']['net']
                                    }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8 right bottom">IFSC No. - MAHB0000318</div>
                            <div class="col-4"></div>
                        </div>
                        <div class="row">
                            <div class="col-8 right bottom">
                                Bank Name - Bank of Maharashtra
                            </div>
                            <div class="col-4"></div>
                        </div>
                        <div class="row">
                            <div class="col-8 right bottom">
                                Bank Branch - Prabhadevi, Mumbai - 400025
                            </div>
                            <div class="col-4 bottom"></div>
                        </div>
                        <div class="row">
                            <div class="col-8 right flexbox">
                                <span>सूचना : </span>
                                <span class="alignment">1) <span>जाहिरात रक्कमेचा धनादेश 'हिंदुस्थान प्रकाशन संस्था' या
                                        नावाने
                                        पाठवावा.</span></span>
                                <span class="alignment">2) <span>जाहिरात देयकाबाबत काही तक्रार असल्यास हे देयक
                                        मिळाल्यापासून १०
                                        दिवसांच्या आत
                                        कार्यालयात संपर्क करावा.</span></span>
                                <span class="alignment">3) <span>चूक/भूल द्यावी घ्यावी</span></span>
                            </div>
                            <div class="col-4 flexadd">
                                <span class="fontBold">हिंदुस्थान प्रकाशन संस्था</span>
                                <span>जाहिरात विभागाकरिता</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
</ng-container>