<div>
    <div style="margin-left: 80%;  margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printTitle="Label" printSectionId="print-section" ngxPrint>
            print
        </button>
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <!-- <div class="row">
            <div class="col-6">
                <div class="col-3">
                    <img src="../../../assets/img/brand/red.png" alt="" width="100">
                </div>
                <div class="col-9">

                </div>
            </div>
        </div> margin-bottom: 4%;margin-top: 1.5%; -->

        <table style="margin: 15px;">
            <thead>
                <tr>
                    <td>
                        <div class="header-space">&nbsp;</div>
                        <h3  style="font-size: 16px; font-weight: 600;text-align: center;">वितरक नाव : {{data.dist_rep_name}} - {{data.dis_mode}}</h3>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class="row">
                           
                            <div class="col-3" *ngFor="let item of labelsData" style="page-break-inside: avoid;">
                                <!-- <span style="font-size: 16px; font-weight: 600;">Sub No:-{{item["subno"] }}
                                </span><br/> -->
                                <span style="font-size: 16px; font-weight: 600;">
                                    {{ item['shortname'] }}{{ item['month']}}/{{ item['subno']}}-{{ item['year'] }}
                                </span><br>
                                <span style="font-size: 16px; font-weight: 600;">{{ item["title"] }} {{ item["name"]
                                    }}</span><br />
                                <span style="font-size: 16px; font-weight: 600;">{{ item["address"] }} {{
                                    item["taluka"] }}</span><br />
                                <span style="font-size: 16px; font-weight: 600;">{{ item["district"] }}</span>
                                <span style="font-size: 16px; font-weight: 600;" *ngIf="item['pincode'] != 0"> - {{
                                    item["pincode"]
                                    }}</span><br *ngIf="item['pincode'] != 0" />
                                <span style="font-size: 16px; font-weight: 600;" *ngIf="item['state'] != ''">{{
                                    item["state"] }}
                                </span><br />
                                <span style="font-size: 16px; font-weight: 600;">{{item["mobileNumber"] }}
                                </span> &nbsp;
                                
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="header">दि. १४/०९/२३ साप्तहिक विवेक</div>
    </div>
</div>