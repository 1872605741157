<div>
    <div style="margin-left: 80%;  margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" (click)="printSelectedSections()"  ngxPrint>
            print
        </button>
        <button mat-dialog-close class="btn noPrint">
            Close
        </button>
    </div>
    <ng-container *ngFor="let item of subData">
    <div class="container" [id]="item.id" style="line-height: 1.6rem; width: 80%;">
        <div class="pawati mb-4" >
            <!-- <label style="margin-left: 10px;" class="noPrint">
                <input type="checkbox" [(ngModel)]="item.printEnabled" > Print Section
            </label> -->
            <div class="row" style="margin-left: 5%;border-width: 0 0 1px 0;border-style: solid; border-color: black;">
                <div class="col-2">
                    <img src="../../../assets/img/brand/blue.png" alt="" loading="lazy" width="100" height="100">
                </div>
                <div class="col-10" style="
                font-size: small;
            ">
                    <span>हिंदुस्थान प्रकाशन संस्था</span><br />
                    <span>मुख्य कार्यालय: १२, कामत औद्योगिक वसाहत, ३९६, वीर सावरकर मार्ग, प्रभादेवी, मुंबई - ४००
                        ०२५</span><br />
                    <span>दूरध्वनी: २४२२१४४०/२४३७४१५८ फॅक्स: २४३६३७५६ ई-पत्र: vivekwkl&#64;yahoo.com</span><br />
                    <span>प्रशासकीय कार्यालय : द्वारा हिंदुस्थान प्रकाशन संस्था, १२०२, विवेक भवन (कृष्णा रिजन्सी), प्लॉट
                        क्र. ४०, सेक्टर क्र. ३०</span><br />
                    <span>सानपाडा, नवी मुंबई ४००७०५. दूरध्वनी: २७८१२०४८, २७८१०२३५. फॅक्स: २७८१०२३७</span>
                </div>
            </div>
            <div class="row" style="margin-left: 5%;font-size: small;">
                <div class="col-4" style="margin-top: 1%;">पावती क्र : {{ item['rcpt_no'] }}</div>
                <div class="col-3" style="border: 1px solid black;padding: 5px; border-radius: 5px;">वर्गणीदार नोंदणी
                    पावती
                </div>
                <!-- <div class="col-1"></div> -->
                <div class="col-3" style="margin-top: 1%;">पावती दि : {{ item['entry_date'] | date:'dd/MM/yyyy' }}</div>
            </div>
            <div class="row" style="margin-left: 10%;margin-top: 1%;font-size: small;">
                <div class="col-6">
                    <span>वर्गणीदार : {{ item['title'] }}. {{ item['name'] }}</span><br />
                    <!-- <span>पत्ता <span style="margin-left: 8%;">: द्वारा : {{ item['rep_name'] }}</span></span><br /> -->
                    <span style="margin-left: 17%;">{{ item['address'] }}</span><br />
                    <!-- <span style="margin-left: 17%;">manu mata mandir, Aadgoan</span><br /> -->
                    <span style="margin-left: 17%;">{{ item['district'] }} {{ item['pincode'] }}</span>
                </div>
                <div class="col-1"></div>
                <div class="col-3" style="margin-left: -4%">
                    <div class="col-12" style="border: 2px solid black;padding: 10px; border-radius: 5px;">
                        <span style="margin-left: 7%;padding-left: 20px;">ग्राहक
                            क्र.</span><br />
                        <span style="padding-top: 1%;">{{ item['subscription_type'] }}
                            {{ item['month'] }}/{{ item['year'] }}/{{ item['subno'] }}</span>
                    </div>
                    <span style="margin-left: 3%;margin-top: 1%;">कच्चा पा क्र.</span><br />
                    <span style="margin-left: 3%;">27064 31/03/22</span>
                </div>
            </div>
            <div class="row" style="margin-left: 6%;margin-top: 1%;font-size: small;">
                <div class="col-6">
                    <span>वर्गणी रक्कम रु. {{ item['total_amt'] }} <span style="margin-left: 5%;">रोख</span></span>
                </div>
            </div>
            <div class="row" style="margin-left: 4%;margin-top: 1%;font-size: small;">
                <div class="col">
                    <span>आपली वर्गणी दि :</span>
                    <span style="margin-left: 4%;">{{ item['sub_perid_from_date'] | date:'dd/MM/yyyy' }} ते : {{
                        item['sub_perid_to_date'] | date:'dd/MM/yyyy'
                        }}</span>
                    <span style="margin-left: 7%;">कालावधीकरिता साभार पोहचली. धन्यवाद!</span>
                </div>
            </div>
            <div class="row" style="margin-left: 4%;margin-top: 1%;font-size: small;">
                <div class="col">
                    <span>(धनादेश वाटल्यावरच पावती अधिकृत होईल.)</span>
                </div>
            </div>
            <div class="row" style="margin-left: 4%;margin-top: 1%;font-size: small;">
                <div class="col">
                    <span>पत्रव्यवहार करताना ग्राहक क्रमांकाचा कृपया निर्देश करावा.</span>
                    <span style="margin-left: 10%;border: 1px solid black;padding: 5px;border-radius: 5px;">लेखा
                        विभाग</span>
                    <span style="margin-left: 4%;border: 1px solid black;padding: 5px;border-radius: 5px;">वितरण
                        विभाग</span>
                </div>
            </div>
            <div style="border-width: 0 0 1px 0;border-style: solid; border-color: black;margin-top: 5%;margin-bottom
            : 5%;"></div>
        </div>
    </div>
    </ng-container>
</div>