import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, observable } from 'rxjs';
import { Newsubscription } from './newsubscription';

@Injectable({
  providedIn: 'root',
})
export class DigisubservicesService {
  // PHP_API_SERVER = 'https://192.168.1.31/VIVEK';
  PHP_API_SERVER = 'https://vivekweb.in/VivekApplication';

  constructor(private httpClient: HttpClient) {}

  get_User(dataFromUI) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/admin_auth.php`,
      dataFromUI
    );
  }

  get_AllRepresentative_List(): Observable<any> {
    return this.httpClient.get(`${this.PHP_API_SERVER}/read_Allrep_list.php`);
  }

  get_distinct_city_list(): Observable<any> {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/read_all_distinct_city.php`
    );
  }

  read_RepById(ds_repid: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/read_repbyid.php`, {
      ds_repid,
    });
  }

  read_distwise_pincode_report(start_date, end_date, disct_list) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_disct_pincode_report.php`,
      { start_date, end_date, disct_list }
    );
  }

  getSubmagByIdorNumberCancel(mobileNumber) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/portal_read_ntfc_mag_detail.php`,
      { mobileNumber }
    );
  }

  getaddolddata(data) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_existing_advt_data.php`,
      { data }
    );
  }

  getaddadvttype(data) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_advt_type.php`,
      { data }
    );
  }
  addupdateddata(data) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_advt_type.php`,
      { data }
    );
  }
  updateprintorder(prusthaData) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_print_order_prustha1.php`,
      { prusthaData }
    );
  }

  updateprintorder2(typeData) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_print_order_prustha2.php`,
      { typeData }
    );
  }

  updateprintorder3(orderid,total ) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_total_prushta.php`,
      { orderid , total }
    );
  }

  get_old_data() {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_existing_advt_data.php`,
      {  }
    );
  }
  get_getadvttype() {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_advt_type.php`,
      {  }
    );
  }

  getPincodeDetails(pincode) {
    // console.log(pincode);
    return this.httpClient.get<any>(
      `https://api.postalpincode.in/pincode/${pincode}`
    );
  }

  getPincodeData(pincode) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_pincode_data.php`,
      { pincode }
    );
  }

  getCountry(): Observable<any> {
    return this.httpClient.get<any>(`${this.PHP_API_SERVER}/get_Country.php`);
  }

  getState(country_code) {
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/get_states.php`, {
      country_code,
    });
  }

  getstates(country_code: number) {
    // console.log(country_code);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_all_states.php`,
      { country_code }
    );
  }

  getDistrict(state_id: any) {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_District.php`,
      { state_id }
    );
  }

  getTaluka(district_id:any) {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_taluka.php`,
      { district_id }
    );
  }
  getWTaluka(district_id:any) {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_taluka_name.php`,
      { district_id }
    );
  }

  getTaluka1(district_id:any) {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_taluka.php`,
      district_id 
    );
  }


  // getTaluka(district_id) {
  //   return this.httpClient.post<any>(`${this.PHP_API_SERVER}/get_taluka.php`, district_id);
  // }

  getCity(state_id: number) {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_all_cities.php`,
      { state_id }
    );
  }

  updateRepData(dataFromUI) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_rep_data.php`,
      dataFromUI
    );
  }

  updateRep_book_commision(ds_repid, ds_repcomm) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_rep_book_comission.php`,
      { ds_repid, ds_repcomm }
    );
  }

  getRepwiseAddress(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_repwise_address.php`
    );
  }

  getSubscribersAddressData(
    start_date,
    end_date,
    title,
    district,
    taluka,
    subtype,
    complimentary
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_subscribers_address.php`,
      { start_date, end_date, title, district, taluka, subtype, complimentary }
    );
  }

  getSubscribersAddressTitles(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscriber_address_titles.php`
    );
  }

  // Offiece rep

  getSubscribersAddressTitles1(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscriber_address_titles.php`
    );
  }

  getSubscribersAddressDistricts(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscriber_address_district.php`
    );
  }

  getSubscribersAddressTalukas(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscriber_address_taluka.php`
    );
  }

  getSubscribersAddressSubType(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscriber_address_subtype.php`
    );
  }

  getSubscriberLedgerData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscriber_ledger_data.php`
    );
  }

  getSubscriberledgerDataCount() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscriber_ledger_total.php`
    );
  }

  getSubscriberLedgerDataWithPagination(
    pageno,
    totalrecordsperpage
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_subscriber_ledger_data_pagination.php`,
      { pageno, totalrecordsperpage }
    );
  }

  getAllSub(): Observable<any> {
    return this.httpClient.get(`${this.PHP_API_SERVER}/read_sub_list.php`);
  }

  getSearchedSub(searchedSub) {
    ////console.log(searchedSub)
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/searchsubname.php`,
      searchedSub
    );
  }

  addSubscriber(newsub: any) {
    // console.log(dataFromUI);
    // var headers = new HttpHeaders();
    // headers.append('Accept', 'application/json');
    // headers.append('Content-Type', 'application/json');
    // headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    // headers.append('Access-Control-Allow-Origin', '*');
    // headers.append('Access-Control-Allow-Headers', '*');

    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_sub.php`,
      { newsub }
    );
    //return null;
  }

  addSub(newsub: any) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_sub_data.php`,
      { newsub }
    );
  }
  setPaymentcomm(req){
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_advt_rep_commission.php`,
      req 
    );
  }

  addPackage(pkgdata: any) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_new_package.php`,
      { pkgdata }
    );
  }

  addPackage1(pkgdata: any) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_package_1.php`,
      { pkgdata }
    );
  }

  addPackage3(pkgdata: any) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_package_3.php`,
      { pkgdata }
    );
  }

  addPackage5(pkgdata: any) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_package_5.php`,
      { pkgdata }
    );
  }

  updatePackage(pkgdata: any) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_package.php`,
      { pkgdata }
    );
  }

  updatePackage1(pkgdata: any) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_pkg_1_year.php`,
      { pkgdata }
    );
  }

  updatePackage3(pkgdata: any) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_pkg_3_year.php`,
      { pkgdata }
    );
  }

  updatePackage5(pkgdata: any) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_pkg_5_year.php`,
      { pkgdata }
    );
  }

  getsubcrdtvoucherPackageData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_pkgdata_crdt_voucher.php`
    );
  }

  getdistname(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_district_name.php`
    );
  }

  gettaluka(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_taluka_name.php`
    );
  }
  getprant(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_all_prant.php`
    );
  }
  get_dis_rep(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_dis_rep_list.php`
    );
  }

  getdistmode(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_dis_mode.php`
    );
  }
  getsubdistmode(dis_mode) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_dis_sub_mode.php`,
      { dis_mode }
    );
  }

  getPackageData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_package_data.php`
    );
  }

  getPackageBookingData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_pkg_booking_data.php`
    );
  }

  // getPackageBookingData3(): Observable<any> {
  //   return this.httpClient.get<any>(
  //     `${this.PHP_API_SERVER}/get_pkg_booking_data3.php`
  //   );
  // }

  // getPackageBookingData5(): Observable<any> {
  //   return this.httpClient.get<any>(
  //     `${this.PHP_API_SERVER}/get_pkg_booking_data5.php`
  //   );
  // }

  getPackageId(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_next_package_id.php`
    );
  }

  getDebitVoucherPackageId(date, bankcashaccount) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_next_debit_voucher_id.php`,
      { date, bankcashaccount }
    );
  }

  updateSub(newsub: any) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_sub_data.php`,
      { newsub }
    );
  }

  deleteSub(id) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_subscribers_basic_details.php`,
      { id }
    );
  }

  addSubDetails(newsub: any) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_sub_details.php`,
      { newsub }
    );
  }

  updateSubType(subid, duration) {
    return this.httpClient.put(`${this.PHP_API_SERVER}/update_sub_type.php`, {
      subid,
      duration,
    });
  }

  getDatewiseAndRepwiseList(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_datewise_or_repwise_list.php`
    );
  }

  getDatewiseAndRepwiseData(
    start_date,
    end_date,
    rep_name,
    off_rep_name,
    district_name,
    taluka_name
  ): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_datewise_or_repwise_data.php`,
      {
        start_date,
        end_date,
        rep_name,
        off_rep_name,
        district_name,
        taluka_name,
      }
    );
  }

  getRepNameofSubDetails() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_rep_name_subDetails.php`
    );
  }

  getOfficeRepwiseList(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_off_rep_wise_list.php`
    );
  }

  getRemainingRenewableReceiptData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_renewable_remaining_data.php`
    );
  }

  getRepresentativeSubAddressData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_representativewise_sub_address.php`
    );
  }

  getDeptwiseOffRepList(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_deptwise_assign_off_rep_list.php`
    );
  }

  getSubscriberRcptNo(date): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_sub_rcpt_no.php`,
      { date }
    );
  } 

  getSubscriberlatestRcptNo(date, rcpt_no): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_rcpt_newNo.php`,
      { date , rcpt_no }
    );
  }

  getSubscriberEntryDate(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_subscription_entry_date.php`,
      { id }
    );
  }

  NewMagazine(newmag, paymethod: string): Observable<any> {
    // console.log('newmag value ui', newmag);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_mag.php`,
      { newmag, paymethod }
    );
  }

  updateCreditLimit(
    ds_repid: number,
    ds_credit: number,
    ds_tempcredit: number
  ): Observable<any> {
    ////console.log(ds_repid, ds_credit , ds_tempcredit)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_rep_credit.php`,
      { ds_repid, ds_credit, ds_tempcredit }
    );
  }

  insertComissionLimit(
    ds_repid: number,
    ds_custmobile: number,
    ds_commamt: number,
    ds_id: number
  ): Observable<any> {
    ////console.log(ds_repid, ds_custmobile , ds_commamt)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_rep_comission.php`,
      { ds_repid, ds_custmobile, ds_commamt, ds_id }
    );
  }

  insertAdvtComissionLimit(
    ds_repid: number,
    ds_custmobile: number,
    ds_commamt: number,
    advt_id: number
  ): Observable<any> {
    ////console.log(ds_repid, ds_custmobile , ds_commamt)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_rep_advt_comission.php`,
      { ds_repid, ds_custmobile, ds_commamt, advt_id }
    );
  }

  getRepcredit(ds_repid: number) {
    ////console.log(ds_repid)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_rep_credit.php`,
      { ds_repid }
    );
  }

  addNewAdmin(dataFromUI) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_new_admin.php`,
      dataFromUI
    );
  }

  updateSubscriber(dataFromUI) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/cancel_subscriber.php`,
      dataFromUI
    );
  }

  cancelRepresentative(dataFromUI) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/cancel_representative.php`,
      dataFromUI
    );
  }

  getRepresentative(dataFromUI) {
    //////console.log("Data from Service : ",dataFromUI);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_representative.php`,
      dataFromUI
    );
  }

  getPortalAllRepList() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/portal_all_rep_list.php`
    );
  }

  getmaxadminid() {
    return this.httpClient.get(`${this.PHP_API_SERVER}/read_maxadmin.php`);
  }

  getPortalRepWiseCommission() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/portal_repwise_commission.php`
    );
  }

  getAllBooks() {
    return this.httpClient.get(`${this.PHP_API_SERVER}/read_all_books.php`);
  }

  getAllBookData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_all_book_data.php`
    );
  }

  getAllBooksData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_books_all_data.php`
    );
  }

  getBookData(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_data.php`,
      {
        id,
      }
    );
  }

  insertMagazineIssueGranthCate(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_magazine_issue_granth_category.php`,
      { data }
    );
  }

  getMagazineIssueGranthCategoryData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_magazine_issue_category_data.php`
    );
  }

  updateMagazineIssueGranthCategory(data, oldmag_name) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_magazine_and_category.php`,
      { data, oldmag_name }
    );
  }

  updateIssueDate(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_issue_dates.php`,
      { data }
    );
  }

  updateReturnReceipt(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_return_receipt.php`,
      { data }
    );
  }

  updateIssueDatetoInactive(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_issue_date_to_inactive.php`,
      { id }
    );
  }

  updateMagazineIssueGranthCategorytoInactive(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/updateMagazineInActive.php`,
      { id }
    );
  }

  getBookDataforCancel(bookname) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_data_for_cancel.php`,
      { bookname }
    );
  }

  getAdvtSizes(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_advt_sizes.php`
    );
  }

  getBookRegistration() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/read_book_registration_report.php`
    );
  }

  getPortalSubRenewal() {
    return this.httpClient.get(`${this.PHP_API_SERVER}/portal_sub_renewal.php`);
  }

  getSubmagByIdorNumber(mobileNumber) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_ntfc_mag_detail.php`,
      { mobileNumber }
    );
  }

  getSubRenewal(start_date, end_date, rep_id) {
    // console.log(start_date, end_date, rep_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/portal_sub_renewal.php`,
      { start_date, end_date, rep_id }
    );
  }

  getBookwiseReport(book_name, start_date, end_date) {
    // console.log(book_name, start_date, end_date);
    // read_book_report_bookwise.php
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_book_report_bookwise.php`,
      { book_name, start_date, end_date }
    );
  }

  addSubscriptionType(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_subscription_type.php`,
      { data }
    );
  }

  updateSubscriptionType(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_subscription_type.php`,
      { data }
    );
  }

  getSubscriptionType() {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_subscription_type.php`,
      {}
    );
  }

  removeAdmin(id: number) {
    //console.log(id)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/Delete_admin.php`,
      { id }
    );
  }

  removeMag(magname) {
    // console.log(magname);
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/Delete_mag.php`, {
      magname,
    });
  }

  AddNewMag(
    magname,
    magedition,
    magprintamount,
    magdigitalamount,
    magbothamount,
    magyear,
    magdesc,
    magapp,
    magfullname
  ) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_new_mags.php`,
      {
        magname,
        magedition,
        magprintamount,
        magdigitalamount,
        magbothamount,
        magyear,
        magdesc,
        magapp,
        magfullname,
      }
    );
  }

  changeMagPrice(
    magname,
    magedition,
    magprintamount,
    magdigitalamount,
    magbothamount,
    magyear
  ) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/change_mag_price.php`,
      {
        magname,
        magedition,
        magprintamount,
        magdigitalamount,
        magbothamount,
        magyear,
      }
    );
  }

  AddNewevent(
    event_name,
    event_venue,
    event_fee,
    event_todate,
    event_fromdate,
    imagepath
  ) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_new_events.php`,
      {
        event_name,
        event_venue,
        event_fee,
        event_todate,
        event_fromdate,
        imagepath,
      }
    );
  }

  AddNewBook(book) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_book.php`,
      {
        book,
      }
    );
  }

  updateBook(bookform) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/update_new_book.php`, {
      bookform,
    });
  }

  addbooksize(booksize) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_book_size.php`,
      { booksize }
    );
  }

  AddNewBookStock(bookeditiondata) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_book_stock.php`,
      { bookeditiondata }
    );
  }

  updateBookSubStockDetails(bookform) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_book_stock_details.php`,
      { bookform }
    );
  }

  getBookEdition(bookname) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_Book_Edition.php`,
      { bookname }
    );
  }

  AddNewBookAvailableStock(bookid, total_copies, available_stock) {
    //console.log(magname)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_book_available_stock.php`,
      { bookid, total_copies, available_stock }
    );
  }

  cancelSubscriberrcpt(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_subscriber_rcpt_cancellation.php`,
      { id }
    );
  }

  getReceiptLedger(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_receipt_ledger.php`
    );
  }

  getReceiptLedgerTotal() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_receipt_ledger_total.php`
    );
  }

  // getReceiptLedgerWithPagination(pageno, totalrecordsperpage): Observable<any> {
  //   return this.httpClient.post<any>(
  //     `${this.PHP_API_SERVER}/get_receipt_ledger_with_pagination.php`,
  //     { pageno, totalrecordsperpage }
  //   );
  // }

  getReceiptLedgerData(startdate, enddate, cust_name): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_receipt_ledger_with_pagination.php`,
      { startdate, enddate, cust_name }
    );
  }

  getReceiptLedgerData3(data): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_receipt_ledger_with_pagination3.php`,
      { data }
    );
  }

  
  getalldata(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_receipt_ledger_with_pagination2.php`,
      {  }
    );
  }
  

  getGSTReportData(startdate, enddate) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_gst_report_data.php`,
      { startdate, enddate }
    );
  }

  UpdateNewBookAvailableStock(bookid, total_copies, available_stock) {
    //console.log(magname)
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_book_available_stock.php`,
      { bookid, total_copies, available_stock }
    );
  }

  UpdateRcptCancellation(bookid) {
    //console.log(magname)
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_book_rcpt_cancellation.php`,
      { bookid }
    );
  }

  AddupdatedBookStock(bookid, total, available, comment) {
    // console.log(bookid, total, available, comment);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_new_book_stock.php`,
      { bookid, total, available, comment }
    );
  }

  ReadAllBookStock() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/read_all_books_stock.php`
    );
  }

  removeEvent(event_id) {
    // console.log(event_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/Delete_event.php`,
      { event_id }
    );
  }

  updateBookStock(book_id) {
    // console.log(book_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_new_book_stock.php`,
      { book_id }
    );
  }

  Rep_invoiceBooks(rep_id, start_date, end_date) {
    // console.log(rep_id, start_date, end_date);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_rep_invoice_books.php`,
      { rep_id, start_date, end_date }
    );
  }

  getdepwiserepcount() {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_depwise_rep_count.php`,
      {  }
    );
  }

  getcountry() {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_Country.php`,
      {  }
    );
  }

  getseniority() {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_seniority.php`,
      {  }
    );
  }

  getoffrepname() {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_off_rep_list.php`,
      {  }
    );
  }

  
  getdept() {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_department.php`,
      {  }
    );
  }

  getAdvtSizeByCategory(category) {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_sizes_by_category.php`,
      { category }
    );
  }

  getListofassignrep(rep_type) {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_depwise_rep.php`,
      { rep_type }
    );
  }

  getListofassignoff(off_rep) {
    // console.log(category);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_rep_offrepwise.php`,
      { off_rep }
    );
  }

  getAdvtCategory() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_category_list.php`,
      {}
    );
  }

  getAdvtissuename() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_all_Issue_Dates.php`,
      {}
    );
  }
  getupdateolddata(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_old_advt.php`,
      {data}
    );
  }

  getIssueSellerName() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_issue_seller_name.php`,
      {}
    );
  }

  getSbhetdata() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_issue_seller_saprem_bhet.php`,
      {}
    );
  }
  getdeactivedata() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_issue_seller_saprem_bhet_deactive.php`,
      {}
    );
  }

  getDistributionRepresentativeSBhetHastewise(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_dis_repwise_hand_delivery.php`
    );
  }

  getnewapi(preweekfirstday){
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_all_distributors_total.php`,
      { preweekfirstday}
    );
  }

  getJavakprati(req) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_advt_jawak.php`,
      { req}
    );
  }

  addBookSellerEntry(bookseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_book_seller_entry.php`,
      { bookseller }
    );
  }

  updateBookSellerEntry(bookseller) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_book_seller_entry.php`,
      { bookseller }
    );
  }

  addBookSellerEntryBookingDetails(bookseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_book_seller_entry_booking_details.php`,
      { bookseller }
    );
  }

  getBookSellerEntry() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_seller_entry.php`,
      {}
    );
  }

  getBookSellerEntryForBookRegistration() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_seller_entry_for_book_registration.php`,
      {}
    );
  }

  getBookRegistrationDataWithoutInvoice(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_registration_without_invoice.php`,
      {}
    );
  }

  getBookCrdtNote(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_crdt_note.php`,
      {}
    );
  }

  getBookCrdtNoteBooks(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_crdt_note_books.php`,
      { id }
    );
  }

  // getBookWiseReport() {
  //   return this.httpClient.post(
  //     `${this.PHP_API_SERVER}/get_book_wise_report.php`,
  //     {}
  //   );
  // }

  getBookCrdtVoucher() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_crdt_voucher.php`,
      {}
    );
  }

  getBookOutStandingData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_out_standing_data.php`
    );
  }

  getBookRcptForLedger(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_rcpt_for_ledger.php`
    );
  }

  //Merge data of Book Seller Ledger
  getBookSellerLedgerData(name) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_seller_ledger_data.php`,
      { name }
    );
  }

  getBookSellerNames() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_seller_name.php`,
      {}
    );
  }

  getBookSellerNames1() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_seller_name1.php`,
      {}
    );
  }

  getBookRcptPymtData() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_data_for_book_rcpt_pymt.php`,
      {}
    );
  }

  insertBookPymtRcpt(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_book_pymt_rcpt_data.php`,
      { data }
    );
  }

  getPymtRcptNo(): Observable<any> {
    return this.httpClient.get<any>(`${this.PHP_API_SERVER}/get_rcpt_no.php`);
  }

  getCrdtNoteNo(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_crdtnote_no.php`
    );
  }

  insertCrdtNoteData(crdtNoteData) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_creditnote.php`,
      { crdtNoteData }
    );
  }

  insertCrdtNoteBooks(crdtNoteData) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_creditnote_books.php`,
      { crdtNoteData }
    );
  }

  insertBookPymtRcptandInvoicedata(bookrcptid, bookinvoiceid) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_book_pymt_rcpt_and_invoice_data.php`,
      { bookrcptid, bookinvoiceid }
    );
  }

  getBookSellerSpecificData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_seller_specific_data.php`
    );
  }

  getBookSellerSpecificData1(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_seller_data_for_additional_entry.php`
    );
  }

  getRcptWiseBooks(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_rcptwise_book_data.php`,
      { id }
    );
  }

  getRcptWiseBooks1(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_rcptwise_book_data1.php`,
      { id }
    );
  }

  getRcptWiseBooksForCreditNote(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_rcptwise_book_for_creditnote.php`,
      { id }
    );
  }

  updateRcptWiseBooks(bse_id, id, book_name, rcvdamt, remainingamt) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_rcptwise_book_data.php`,
      { bse_id, id, book_name, rcvdamt, remainingamt }
    );
  }

  updateRcptWiseBooks1(id, rcvdamt, remainingamt) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_rcptwise_book_data1.php`,
      { id, rcvdamt, remainingamt }
    );
  }

  getSingleBookSellerEntry(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_single_book_seller_entry.php`,
      { id }
    );
  }

  getBookInviceForView(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_invoice_for_view.php`,
      { id }
    );
  }

  getBookInviceDataForView(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_invoice_data_for_view.php`,
      { id }
    );
  }

  addBookData(bookdata) {
    // console.log('bookdata', bookdata);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_book_data1.php`,
      { bookdata }
    );
  }

  getBookInvoiceData() {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_data_book_invoice.php`,
      {}
    );
  }
  getBookDataForCancel(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_data_for_cancel_order.php`,
      { id }
    );
  }

  getBooks(id) {
    // console.log('bookdata', bookdata);
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/get_books.php`, {
      id,
    });
  }

  updateBooksAsDeliverd(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_books_delivered.php`,
      {
        id,
      }
    );
  }

  getBookData1() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_data1.php`,
      {}
    );
  }

  getBookBillingData(name) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_billing_data.php`,
      { name }
    );
  }

  getBookDataforDelivery() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_book_data_for_delivery.php`,
      {}
    );
  }

  addAdvtSizes(advtdata) {
    // console.log(advtdata);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_advt_sizes.php`,
      { advtdata }
    );
  }

  getAllAdvtSizes(): Observable<any> {
    // console.log(advtdata);
    return this.httpClient.get<any>(`${this.PHP_API_SERVER}/getAdvt_Sizes.php`);
  }

  updateAdvtSizes(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/updateAdvtSizes.php`,
      { data }
    );
  }

  updateAdvtSizeInactive(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/updateAdvt_Size_Inactive.php`,
      { id }
    );
  }

  addIssueSellerEntry(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_issue_seller_entry.php`,
      { issueseller }
    );
  }

  addIssueSellerSaprembhet(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_issue_seller_saprem_bhet.php`,
      { issueseller }
    );
  }

  addstart_end_dates_to_rep(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_issue_seller_saprem_bhet_deactive.php`,
      { issueseller }
    );
  }

  updatesbhetdata(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_issue_seller_saprem_bhet.php`,
      { issueseller }
    );
  }
  updatesdates(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_issue_seller_saprem_bhet_deactive.php`,
      { issueseller }
    );
  }
  addcountry(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_countries.php`,
      { data }
    );
  }

  addseniority(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_seniority.php`,
      { data }
    );
  }

  updatecountry(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_countries.php`,
      { data }
    );
  }

  updateseniority(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_seniority.php`,
      { data }
    );
  }

  deleteIssueSellerSaprembhet(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_issue_seller_saprem_bhet.php`,
      { issueseller }
    );
  }
  deleteIssueStartEndDate(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_issue_seller_saprem_bhet_deactive.php`,
      { issueseller }
    );
  }

  deleteolddatalist(issueseller) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_issue_seller_entry.php`,
      { issueseller }
    );
  }

  deletemaster(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_subscription_type.php`,
      { data }
    );
  }

  updateIssueSellerEntry(issueseller) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_issue_seller_entry.php`,
      { issueseller }
    );
  }

  addOfficeRep(addofficerep) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_office_rep_data.php`,
      { addofficerep }
    );
  }

  adddeptwiseOfficeRep(deptwiserep) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_deptwise_office_rep.php`,
      { deptwiserep }
    );
  }

  updateDeptwiseOffRepAssign(deptwiserep) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_deptwise_office_rep.php`,
      { deptwiserep }
    );
  }

  getBookWiseRep(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_store_wise_rep.php`
    );
  }

  getSubscriptionWiseRep(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscription_wise_rep.php`
    );
  }

  updateOfficeRep(id, uid, type) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_office_rep.php`,
      { id, uid, type }
    );
  }

  getAdvtWiseRep(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_advt_wise_rep.php`
    );
  }

  getNextBookingNo(date) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_next_booking_no.php`,
      { date }
    );
  }

  getIssueWiseRep(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_issue_wise_rep.php`
    );
  }

  getNextIssueSerialNo(issuedate) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_issue_serial_no.php`,
      { issuedate }
    );
  }

  setnewbookingnumber() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_latest_invoice.php`,
      {  }
    );
  }

  getAdvtcatsize(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_size_count.php`,
      { data }
    );
  }

  addIssueSellerAgent(issueselleragent) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_issue_seller_agent.php`,
      { issueselleragent }
    );
  }

  addAdvtDates(issuedata) {
    // console.log('issue dates', issuedata);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_advt_dates.php`,
      { issuedata }
    );
  }

  checkDuplicateIssueDate(issueName, issueDate, publishedDate) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/check_repeat_issue_date.php`,
      { issueName, issueDate, publishedDate }
    );
  }

  getAdvtByid(advt_id) {
    // console.log(advt_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_advt_details_by_id_mobile.php`,
      { advt_id }
    );
  }

  removeBook(book_id) {
    // console.log(book_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_book_status.php`,
      { book_id }
    );
  }

  getSubByIdorNumber(sub_id, mobileNumber) {
    //console.log(sub_id, mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_ntfc_sub_details.php`,
      { sub_id, mobileNumber }
    );
  }

  getAdvtMobileorid(owner_name, mobileNumber, advt_name) {
    //console.log(sub_id, mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_advt_person_by_mobile.php`,
      { owner_name, mobileNumber, advt_name }
    );
  }

  cancelAdvt(id) {
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/cancelAdvt.php`, {
      id,
    });
  }

  cancelContractDetails(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/cancelContractDetails.php`,
      {
        id,
      }
    );
  }

  cancelAdvtSubDetails(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/cancelAdvtSubDetails.php`,
      {
        id,
      }
    );
  }

  insertContractDetails(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insertContractDetails.php`,
      { data }
    );
  }

  getNextContractNo(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_next_contract_no.php`
    );
  }

  getContractDetails(advt_id): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_contract_details_using_advt_id.php`,
      { advt_id }
    );
  }
 

  getSubprofile(mobileNumber) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_subscriberProfile.php`,
      { mobileNumber }
    );
  }

  getvalidAdmin(mobileNumber) {
    //console.log(mobileNumber)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_valid_admin.php`,
      { mobileNumber }
    );
  }

  getalladmin() {
    return this.httpClient.get(`${this.PHP_API_SERVER}/read_all_admin.php`);
  }

  getalldeliverydetails() {
    return this.httpClient.get(`${this.PHP_API_SERVER}/read_bookDelivery.php`);
  }

  updateBookDelivery(tracking_id, transport_method, id, transporter_name) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_delivery_details.php`,
      { tracking_id, transport_method, id, transporter_name }
    );
  }

  UpdateDeliveryStatus(id, delivery_date) {
    // console.log(id, delivery_date);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_delivery_status.php`,
      { id, delivery_date }
    );
  }

  CancelDeliveryStatus(id, cancel_date) {
    // console.log(id, cancel_date);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_cancel_delivery_status.php`,
      { id, cancel_date }
    );
  }

  getallEvent() {
    return this.httpClient.get(`${this.PHP_API_SERVER}/read_event_list.php`);
  }

  getPortalSubEmailMobile() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/portal_sub_email_mobile.php`
    );
  }

  getPortalWiseCollectionDetails(rptstartdate, rptenddate) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/portal_datewise_collection_details.php`,
      { rptstartdate, rptenddate }
    );
  }

  getNewSubscibersDataDateWise(repid: number, todate: string, frmdate: string) {
    //console.log("From Service -> Id : ",repid,"date : ",rptdate);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/portal_repwise_datewise_subnames.php`,
      { repid, todate, frmdate }
    );
  }

  cancelSubscriber(dataFromUI) {
    ////console.log("Data from Service : ",dataFromUI);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_subscriber.php`,
      dataFromUI
    );
  }

  getPortaldashboardheadings() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/portal_dashboard_headings.php`
    );
  }

  getPortaldashboardtotalsales() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/portal_dashboard_totalsales.php`
    );
  }

  getPortaldashboardnewsubs() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/portal_dashboard_newsubcount.php`
    );
  }

  getPortaldashboardrepdata() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/portal_dashboard_repdetails.php`
    );
  }

  getMag(): Observable<any[]> {
    ////console.log(ds_repid)
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_magList.php`
    );
  }

  insertNewMag(newmag, paymethod) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(`${this.PHP_API_SERVER}/insert_new_mag.php`, {
      newmag,
      paymethod,
    });
  }

  updateNewMag(
    mobileNumber: number,
    ds_mag1: string,
    ds_mag1edition: string
  ): Observable<any> {
    //console.log(mobileNumber , ds_mag1 , ds_mag1edition)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_mag_renew.php`,
      { mobileNumber, ds_mag1, ds_mag1edition }
    );
  }

  getcustrep(mobile: number) {
    //console.log(mobile)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_repid.php`,
      { mobile }
    );
  }

  getRepbyid(ds_repid) {
    //console.log(ds_repid)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_repbyid.php`,
      { ds_repid }
    );
  }

  addAuthorAddress(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_author_address.php`,
      { data }
    );
  }

  updateAuthorAddress(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_author_address.php`,
      { data }
    );
  }

  deleteAuthorAddress(id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/delete_author_address.php`,
      { id }
    );
  }

  getAuthorData() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_author_data.php`
    );
  }

  getBookSellerAddress() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_book_seller_address.php`
    );
  }

  getOfficeRepData() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_office_rep_data.php`
    );
  }

  updatecreditvoucher(id, crdtvno, amt) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_credit_voucher_book.php`,
      { id, crdtvno, amt }
    );
  }

  updatecreditvoucherofsub(id, amt) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_credit_voucher_sub.php`,
      { id, amt }
    );
  }

  updatecreditvoucherofsubpkg(id, amt) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_crdt_voucher_pkg_sub.php`,
      { id, amt }
    );
  }

  updatebookinvoice(id, invoiceno, remaingamt, check) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_book_receipt.php`,
      { id, invoiceno, remaingamt, check }
    );
  }

  updatecustrep(repid: number, mobileNumber: number) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_cust_rep.php`,
      { repid, mobileNumber }
    );
  }

  insertCustRepdata(mobile: number, repid: number): Observable<any> {
    //console.log(mobile, repid)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_cust_rep.php`,
      { mobile, repid }
    );
  }

  updateMagCancel(
    mobileNumber: number,
    ds_mag1: string,
    ds_mag1edition: string
  ): Observable<any> {
    //console.log(mobileNumber , ds_mag1 , ds_mag1edition)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_mag_cancel.php`,
      { mobileNumber, ds_mag1, ds_mag1edition }
    );
  }

  insertSubRenew(sub_id: number): Observable<any> {
    //console.log(sub_id)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_renewsub_notif.php`,
      { sub_id }
    );
  }

  checksubisexist(ds_mobile: number) {
    // console.log(ds_mobile);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/check_sub_exist.php`,
      { ds_mobile }
    );
  }

  inactivesub(id) {
    // console.log(ds_mobile);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_active_inactive.php`,
      { id }
    );
  }

  checkrepisexist(rep_mobile: number) {
    // console.log(rep_mobile);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/check_rep_exist.php`,
      { rep_mobile }
    );
  }

  makesuperAdmin(id: number, flag: string) {
    // console.log(id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_superAdmin.php`,
      { id, flag }
    );
  }

  checkadminisexist(mobile: number) {
    // console.log(mobile);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/check_admin_exist.php`,
      { mobile }
    );
  }

  checkadminissuper(id: number) {
    // console.log(id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/check_admin_is_super.php`,
      { id }
    );
  }

  getAllContries(): Observable<any[]> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/read_all_countries.php`
    );
  }

  sendSMS(mobile, msg, template) {
    //mobile = 7276169890
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Access-Control-Allow-Headers', '*');
    //console.log(mobile, msg)
    return this.httpClient.post<any>(`${this.PHP_API_SERVER}/send_sms.php`, {
      mobile,
      msg,
      template,
    });
    //return this.httpClient.get<any>(`http://sms6.rmlconnect.net:8080/bulksms/bulksms?username=Oemtrans&password=Oem@1234&type=0&dlr=1&destination=7276169890&source=BRHMAN&message=${msg}` , {headers:headers});
  }

  getPendingPayment(): Observable<any[]> {
    //console.log(ds_repid)
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_pending_payment.php`
    );
  }

  getAdvtPendingPayment(): Observable<any[]> {
    //console.log(ds_repid)
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_advt_pending_payment.php`
    );
  }

  getBookPendingPayment(): Observable<any[]> {
    //console.log(ds_repid)
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_book_pending_payment.php`
    );
  }

  getPrevCommpay(ds_repid) {
    // console.log(ds_repid);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_paybyid.php`,
      { ds_repid }
    );
  }

  getPrevCommpay_book(ds_repid, id) {
    // console.log(ds_repid);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_paybyid_book.php`,
      { ds_repid, id }
    );
  }

  getcurrentcommpay(ds_repid) {
    // console.log(ds_repid);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_currentpaybyid.php`,
      { ds_repid }
    );
  }

  getcurrentcommpay_book(ds_repid, id) {
    // console.log(ds_repid, id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_currentpaybyid_book.php`,
      { ds_repid, id }
    );
  }

  updatepaymentdep(
    id,
    closingoutstanding,
    ispartial,
    comment
  ): Observable<Newsubscription> {
    // console.log(id, closingoutstanding, ispartial);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_payment_deposite.php`,
      { comment, id, closingoutstanding, ispartial }
    );
  }

  updatepaymentdep_book(
    id,
    closingoutstanding,
    ispartial,
    comment
  ): Observable<Newsubscription> {
    // console.log(id, closingoutstanding, ispartial);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_payment_deposite_book.php`,
      { comment, id, closingoutstanding, ispartial }
    );
  }

  updatepaymentdep_advt(
    id,
    closingoutstanding,
    ispartial,
    comment
  ): Observable<Newsubscription> {
    // console.log(id, closingoutstanding, ispartial);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_payment_deposite_advt.php`,
      { comment, id, closingoutstanding, ispartial }
    );
  }

  updatepaymntstus_book(
    bdp_id: number,
    ds_subpymtcmpt: string
  ): Observable<any> {
    // console.log(ds_subpymtcmpt, bdp_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_sub_pymt_book.php`,
      { bdp_id, ds_subpymtcmpt }
    );
  }

  updatepaymntstus_advt(
    advt_id: number,
    ds_subpymtcmpt: string
  ): Observable<any> {
    // console.log(ds_subpymtcmpt, advt_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_sub_pymt_advt.php`,
      { advt_id, ds_subpymtcmpt }
    );
  }

  updatepaymntstus(
    mobileNumber: number,
    ds_subpymtcmpt: string
  ): Observable<any> {
    // console.log(ds_subpymtcmpt, mobileNumber);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_sub_pymt.php`,
      { mobileNumber, ds_subpymtcmpt }
    );
  }

  updateMagpaymntstus(
    ds_subid: number,
    ds_subpymtcmpt: string
  ): Observable<any> {
    // console.log(ds_subpymtcmpt);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_renewsub_pymt.php`,
      { ds_subid, ds_subpymtcmpt }
    );
  }

  insertCashNtfcn(amt: number, repid: number): Observable<any> {
    // console.log(amt, repid);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_cash_ntfcn.php`,
      { amt, repid }
    );
  }

  updateVoucherStatus(voucher_no): Observable<Newsubscription> {
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_voucher_status.php`,
      { voucher_no }
    );
  }

  updatePartialPayment_book(rep_id): Observable<Newsubscription> {
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_partial_payment_book.php`,
      { rep_id }
    );
  }

  updatePartialPayment_advt(rep_id): Observable<Newsubscription> {
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_partial_payment_advt.php`,
      { rep_id }
    );
  }

  updatePartialPayment(rep_id): Observable<Newsubscription> {
    // console.log(rep_id);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_partial_payment.php`,
      { rep_id }
    );
  }

  insertTitle(title): Observable<any> {
    //console.log(sub_id)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_titles.php`,
      { title }
    );
  }

  insertOccupations(occupation, occupation_value): Observable<any> {
    //console.log(sub_id)
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_occupation.php`,
      { occupation, occupation_value }
    );
  }

  getAlltitle(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_titles.php`
    );
  }

  getOccupation(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_occupation.php`
    );
  }

  removetitle(title): Observable<Newsubscription> {
    // console.log(title);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_title.php`,
      { title }
    );
  }

  removeoccupdation(occupation): Observable<Newsubscription> {
    // console.log(occupation);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_occupation.php`,
      { occupation }
    );
  }

  removeStateandCity(state_id): Observable<any> {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_state_and_cities.php`,
      { state_id }
    );
  }
  removeOlddatay(id): Observable<any> {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_old_advt.php`,
      { id }
    );
  }
  removeadvttype(id): Observable<any> {
    // console.log(state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_advt_type.php`,
      { id }
    );
  }

  InsertNewState(state_name, country_code, country_id): Observable<any> {
    // console.log(state_name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_state.php`,
      { state_name, country_code, country_id }
    );
  }

  InsertNewCity(city_name, prant_id, state_id): Observable<any> {
    // console.log(city_name, state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_city.php`,
      { city_name, prant_id, state_id }
    );
  }

  removeCity(id): Observable<any> {
    // console.log(id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_cities.php`,
      { id }
    );
  }

  InsertNewTaluka(taluka_name, pincode, district_id): Observable<any> {
    // console.log(city_name, state_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_taluka.php`,
      { taluka_name, pincode, district_id }
    );
  }

  removeTaluka(id): Observable<any> {
    // console.log(id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_talukas.php`,
      { id }
    );
  }

  // ***************** Prant Master Start *************************
  insertPrantData(prant_name: string) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_prant_master.php`,
      { prant_name }
    );
  }

  getAllPrantData() {
    return this.httpClient.get(`${this.PHP_API_SERVER}/get_all_prant_data.php`);
  }

  removePrant(id: number) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/delete_prant.php`, {
      id,
    });
  }

  updatePrantData(id, prant_name) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_prant_name.php`,
      {
        id,
        prant_name,
      }
    );
  }
  // ***************** Prant Master End ***************************

  getBookCustInvoiceId(date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_cust_invoiceid.php`,
      { date }
    );
  }

  getBookSellerId(date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_book_seller_id.php`,
      { date }
    );
  }

  InsertInvoice(
    rep_name,
    rep_mobile,
    rep_id,
    rep_address,
    state,
    state_code,
    gst,
    total_amount,
    rep_commision,
    cgst,
    sgst,
    igst
  ) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_rep_invoice.php`,
      {
        rep_name,
        rep_mobile,
        rep_id,
        rep_address,
        state,
        state_code,
        gst,
        total_amount,
        rep_commision,
        cgst,
        sgst,
        igst,
      }
    );
  }

  InsertCustInvoice(
    rep_name,
    rep_mobile,
    rep_id,
    rep_address,
    invoice_id,
    invoice_date,
    state,
    state_code,
    gst,
    total_amount,
    total_shipping_charges,
    rep_commision,
    cgst,
    sgst,
    igst
  ) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_cust_invoice.php`,
      {
        rep_name,
        rep_mobile,
        rep_id,
        rep_address,
        invoice_id,
        invoice_date,
        state,
        state_code,
        gst,
        total_amount,
        total_shipping_charges,
        rep_commision,
        cgst,
        sgst,
        igst,
      }
    );
  }

  InsertCustInvoiceSubscription(
    rep_name,
    rep_mobile,
    rep_id,
    rep_address,
    state,
    state_code,
    gst,
    total_amount,
    rep_commision,
    cgst,
    sgst,
    igst
  ) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_cust_invoice_subscription.php`,
      {
        rep_name,
        rep_mobile,
        rep_id,
        rep_address,
        state,
        state_code,
        gst,
        total_amount,
        rep_commision,
        cgst,
        sgst,
        igst,
      }
    );
  }

  InsertCustInvoiceadvertisement(
    rep_name,
    rep_mobile,
    rep_id,
    rep_address,
    state,
    state_code,
    gst,
    total_amount,
    rep_commision,
    cgst,
    sgst,
    igst,
    total_discount,
    discounted_price
  ) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_cust_invoice_advt.php`,
      {
        rep_name,
        rep_mobile,
        rep_id,
        rep_address,
        state,
        state_code,
        gst,
        total_amount,
        rep_commision,
        cgst,
        sgst,
        igst,
        total_discount,
        discounted_price,
      }
    );
  }

  InsertInvoiceData(invoice_id, description, qty, rate, total) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_rep_invoice_data.php`,
      {
        invoice_id,
        description,
        qty,
        rate,
        total,
      }
    );
  }

  insertBankName(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_bank_names.php`,
      { data }
    );
  }

  updateBankName(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_bank_master.php`,
      { data }
    );
  }

  read_bank_names() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/read_bank_names.php`
    );
  }
  read_bank_names1() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_bank_names.php`
    );
  }


  readBankwiseOpeningBalance(banktype) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_bankwise_opening_bal.php`,
      {
        banktype,
      }
    );
  }

  getdataforbookbilling() {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_data_for_book_billing_Register.php`,
      {}
    );
  }

  InsertCustInvoiceData(
    invoice_id,
    bookname,
    authorname,
    bookcopies,
    discount,
    discountamt,
    bookprice,
    booksaleprice,
    total,
    shippingcharges,
    total_amount,
    received_amt,
    remaining_amt,
    rep_commission_precent,
    rep_commission_amt
  ) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_cust_invoice_data.php`,
      {
        invoice_id,
        bookname,
        authorname,
        bookcopies,
        discount,
        discountamt,
        bookprice,
        booksaleprice,
        total,
        shippingcharges,
        total_amount,
        received_amt,
        remaining_amt,
        rep_commission_precent,
        rep_commission_amt,
      }
    );
  }

  InsertCustInvoiceDataSubscription(invoice_id, description, qty, rate, total) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_cust_invoice_data_subscription.php`,
      {
        invoice_id,
        description,
        qty,
        rate,
        total,
      }
    );
  }

  InsertCustInvoiceDataAdvertisment(
    invoice_id,
    description,
    advt_size,
    published_date,
    page_number
  ) {
    //console.log("Mag Form Data",newmag,"paymethod",paymethod);
    console.log(
      invoice_id,
      description,
      advt_size,
      published_date,
      page_number
    );
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_cust_invoice_data_advt.php`,
      {
        invoice_id,
        description,
        advt_size,
        published_date,
        page_number,
      }
    );
  }

  getRepInvoices(start_date, end_date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_rep_invoices.php`,
      { start_date, end_date }
    );
  }

  getCustInvoices(start_date, end_date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_invoices.php`,
      { start_date, end_date }
    );
  }

  getRepCommissionData() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_rep_commission_data.php`
    );
  }

  getCustInvoicesSubscription(start_date, end_date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_invoices_Subscription.php`,
      { start_date, end_date }
    );
  }

  getCustInvoicesadvt(start_date, end_date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_invoices_advt.php`,
      { start_date, end_date }
    );
  }

  getRepInvoicesData(invoice_id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_rep_invoices_data.php`,
      { invoice_id }
    );
  }

  getCustInvoicesData(invoice_id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_invoices_data.php`,
      { invoice_id }
    );
  }

  getVoucher_no(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_voucher_no_by_dept.php`,
      { id }
    );
  }

  getCustInvoicesDataSubscription(invoice_id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_invoices_data_Subscription.php`,
      { invoice_id }
    );
  }

  getadvtInvoiceData(invoice_id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_invoices_data.php`,
      { invoice_id }
    );
  }

  getCustInvoicesName(start_date, end_date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_invoice_books.php`,
      { start_date, end_date }
    );
  }

  getCustInvoicesbookNames(bdp_id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_invoice_books_name.php`,
      { bdp_id }
    );
  }

  getCustbymobileordate(start_date, end_date, mobile, cust_id) {
    console.log(start_date, end_date, mobile, cust_id);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_cust_details.php`,
      { start_date, end_date, mobile, cust_id }
    );
  }

  getOldStartDates(ds_id, mobile) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_mag_start_dates.php`,
      { ds_id, mobile }
    );
  }

  getOldendDates(ds_id, mobile) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_mag_end_dates.php`,
      { ds_id, mobile }
    );
  }

  updateEndDate(mobileNumber, ds_magenddate, ds_magedition): Observable<any> {
    // console.log(mobileNumber, ds_magenddate, ds_magedition);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_mag_end_date.php`,
      { mobileNumber, ds_magenddate, ds_magedition }
    );
  }

  updateStartDate(
    mobileNumber,
    ds_magstartdate,
    ds_magedition
  ): Observable<any> {
    // console.log(mobileNumber, ds_magstartdate, ds_magedition);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_mag_start_date.php`,
      { mobileNumber, ds_magstartdate, ds_magedition }
    );
  }

  updateSubTrackingDetails(
    ds_id,
    transport_method,
    transporter_name,
    tracking_details
  ): Observable<any> {
    // console.log(ds_id, transport_method, transporter_name, tracking_details);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_sub_tracking_details.php`,
      {
        ds_id,
        transport_method,
        transporter_name,
        tracking_details,
      }
    );
  }

  updateUserBlockingStatus(cust_id, setStatus, mobile): Observable<any> {
    // console.log(cust_id, setStatus, mobile);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_sub_block_status.php`,
      { cust_id, setStatus, mobile }
    );
  }

  readRepWiseReports(rep_id, start_date, end_date): Observable<any> {
    // console.log(rep_id, start_date, end_date);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_repwise_details.php`,
      { rep_id, start_date, end_date }
    );
  }

  getlabels(start_date, end_date) {
    // console.log(start_date, end_date);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_post_label.php`,
      { start_date, end_date }
    );
  }

  getlabelsforDispatch(curweekfirstday, preweekfirstday) {
    // console.log(curweekfirstday, preweekfirstday);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_Label_for_Dispatch.php`,
      { curweekfirstday, preweekfirstday }
    );
  }

  addMainExpenseLedger(exp_Data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_main_expense_ledger_name.php`,
      { exp_Data }
    );
  }
  
  updateMainExpenseLedger(id, exp_name, exp_type, opening_bal) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_main_expense_ledger_name.php`,
      { id, exp_name, exp_type, opening_bal }
    );
  }

  getMainExpenseLedger(): Observable<any> {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/get_main_expense_ledger_names.php`
    );
  }
  // getCreditorsReport(start_date, end_date) {
  //   return this.httpClient.get(
  //     `${this.PHP_API_SERVER}/get_main_expense_ledger_names.php`,
  //     {start_date, end_date}
  //   );
  // }
  getCreditorsReport(start_date, end_date) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_creditor_vouchers_report.php`,
      { start_date, end_date }
    );
  }
  insertStampReceipt(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_advt_stamp_rcpt.php`,
      { data }
    );
  }

  addSubExpenseLedger(subexpdata) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_sub_expense_ledger_name.php`,
      { subexpdata }
    );
  }

  updateSubExpenseLedger(subexpdata) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/update_sub_expense_ledger_name.php`,
      { subexpdata }
    );
  }

  getSubExpenseLedger(): Observable<any> {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/get_sub_expense_ledger_names.php`
    );
  }

  getSubExpenseLedgerWithId(id): Observable<any> {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_expense_ledger_names_with_id.php`,
      { id }
    );
  }

  UpdatenewSubscriber(newsub: Newsubscription): Observable<Newsubscription> {
    // console.log(newsub);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_sub_details.php`,
      newsub
    );
  }

  updateNewSubSubscription(
    mobileNumber,
    ds_subisactive,
    isupdated
  ): Observable<Newsubscription> {
    // console.log(mobileNumber, ds_subisactive, isupdated);
    return this.httpClient.post<Newsubscription>(
      `${this.PHP_API_SERVER}/update_sub_details.php`,
      { mobileNumber, ds_subisactive, isupdated }
    );
  }

  getsubnotification(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_ntfcn_sub.php`
    );
  }

  getUpdatesubnotification(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_updtntfcn_sub.php`
    );
  }

  updatesubNotif(sub_id: number, ischecked: string) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_sub_notif.php`,
      { sub_id, ischecked }
    );
  }

  getAdvtTypes(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_advt_types.php`
    );
  }

  getAdvtPersonName(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_advt_person.php`
    );
  }

  getSubNoAndMobileNo(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_subno_mobno.php`
    );
  }

  getSubNames(name): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_sub_names.php`,
      { name }
    );
  }

  getRepNames(name): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_rep_names_renew_report.php`,
      { name }
    );
  }

  getAdvtNames(name): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_advt_names.php`,
      {
        name
      }
    );
  }

  getAdvtNamesForPymt(name) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_advt_invoices_for_pymt.php`,
      { name }
    );
  }

  getCreditVoucherNo(v_no): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_credit_voucher_no.php`,
      { v_no }
    );
  }

  getDebitVoucherNo(v_no): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_debit_voucher_no.php`,
      { v_no }
    );
  }

  checkforreciept(v_no): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_credit_voucher_no.php`,
      { v_no }
    );
  } 

  getCrditVoucherData(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_single_credit_voucher_data_with_id.php`,
      { id }
    );
  }

  getDebitVoucherData(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_single_debit_voucher_data_with_id.php`,
      { id }
    );
  }

  getCreditMainExpenseDataById(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_main_expense_by_crdt_id.php`,
      { id }
    );
  }

  getCreditSubExpenseDataById(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_sub_expense_by_exp_id.php`,
      { id }
    );
  }

  getCrditVoucherPackageData(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_crdt_voucher_package_data.php`,
      { id }
    );
  }

  getAllLabelForDispatch(start_date, end_date, rep_name) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_all_label_for_dispatch.php`,
      { start_date, end_date, rep_name }
    );
  }

  getSubNos(no): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_sub_nos.php`,
      { no }
    );
  }

  getSubMobNos(no): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_sub_mob_nos.php`,
      { no }
    );
  }
  
  creditorsbookingnum(date): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/get_latest_no.php`,
      {date}
    );
  }

  savecreditorsdetail(req): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/insert_creditor_main_expense.php`,
      { req }
    );
  }

  savecreditorsdetail1(items): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/insert_creditor_sub_expense.php`,
      { items }
    );
  }

  getIssuesellersname(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_issue_seller_name.php`
    );
  }

  getrepledgerinfo(start_date,end_date, rep_name) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_rep_db_voucher_data.php`,
      { start_date, end_date, rep_name }
    );
  }

  getrepledgerinfo1(start_date,end_date, rep_name) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_rep_cr_voucher_data.php`,
      { start_date, end_date, rep_name }
    );
  }

  deletecrvoucher( id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_credit_voucher.php`,
      { id }
    );
  }

  getOfficeRepName(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_office_rep_name.php`
    );
  }

  getIssuesellersentry(id): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/get_issue_seller_entry.php`,
      { id }
    );
  }

  getSubData(id): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/get_sub_data.php`,
      { id }
    );
  }

  getSubscriberData(id): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/get_subscriber_data.php`,
      { id }
    );
  }

  getSubscribersDeatilsData(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/get_subscribers_data.php`
    );
  }

  getSubscribersDeatilsDataWithPagination(
    pageno,
    totalrecordsperpage,formattedDate
  ): Observable<any[]> {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/get_subscriber_data_with_pagination.php`,
      {
        pageno,
        totalrecordsperpage,formattedDate
      }
    );
  }
  // getSubscribersDeatilsDataWithFilter(req): Observable<any[]> {
  //   return this.httpClient.post<any[]>(
  //     `${this.PHP_API_SERVER}/get_subscriber_data_with_filter.php`,
  //     {req}
  //   );
  // }

  geTotalSubscribersData() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_subscribers_total_count.php`
    );
  }

  pincodebttaluka( taluka_names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_taluka_pincodewise.php`,
      { taluka_names }
    );
  }

  updateActive(id, startdate, sub_perid_to_date, startremark, days) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_active_rcpt.php`,
      {
        id,
        startdate,
        sub_perid_to_date,
        startremark,
        days,
      }
    );
  }

  getDistributorData(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_distributor_rep.php`
    );
  }

  getSubDetailsData(id): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_subscriber_details_data.php`,
      { id }
    );
  }

  insertReturnRcpt(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_return_receipt.php`,
      { data }
    );
  }

  getReturnRcpt(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_return_receipt.php`
    );
  }

  updateSubDetails(newsub) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_subscriber_details.php`,
      {
        newsub,
      }
    );
  }

  deleteSubDetails(id, voucher_no, amt, flag) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/delete_subscriber_details.php`,
      {
        id,
        voucher_no,
        amt,
        flag,
      }
    );
  }

  insertAdvtSubDetails(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_advt_sub_details.php`,
      { data }
    );
  }

  updateAdvtSubDetails(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_advt_sub_details.php`,
      { data }
    );
  }

  insertNewadvtPerson(newadvt) {
    // console.log(newadvt);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_advt_personinfo.php`,
      { newadvt }
    );
  }

  insertNewadvtdetails(newadvtdtl) {
    // console.log(newadvtdtl);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_new_advt.php`,
      { newadvtdtl }
    );
  }

  updatepaymentreceipt(data) {
    // console.log(newadvtdtl);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_advt_paymnt_rcpt.php`,
      { data }
    );
  }

  updateNewadvtdetails(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/updateNewAdvtDetails.php`,
      { data }
    );
  }

  getOffRepAndRepForRegReport() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_off_rep_for_reg_report.php`
    );
  }

  getRegReportInDetails(): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_reg_report_indetails.php`,
      {   }
    );
  }

   getRegReportInDetails1(req1): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_reg_report_indetails.php`,
      { req1}
    );
  }

  getAdvtSubDetailsById(advt_id): Observable<any> {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_advt_sub_details_by_advtid.php`,
      { advt_id }
    );
  }

  insertIssueSellerAgentAdvtDetails(newadvtdtl) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_issue_seller_agent_advt.php`,
      { newadvtdtl }
    );
  }

  insertCustRepAdvt(mobile, repid) {
    // console.log(mobile, repid);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_cust_rep_advt.php`,
      { mobile, repid }
    );
  }

  getAdvtReports(advt_name, mobile, issue_name, rep_name) {
    // console.log(start_date, end_date);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_details_by_filter.php`,
      { advt_name, mobile, issue_name, rep_name }
    );
  }

  getAdvtRegistrationReports(start_date, end_date, issue_name, issueDates) {
    // console.log(start_date, end_date);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_registration_report.php`,
      { start_date, end_date, issue_name, issueDates }
    );
  }

  getAdvtReportRepWise(start_date, end_date, rep_name) {
    // console.log(start_date, end_date, rep_name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_rep_registration_report.php`,
      { start_date, end_date, rep_name }
    );
  }

  insertAdvt(data) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/insertAdvt.php`, {
      data,
    });
  }

  updateAdvtPerson(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_advt_person_details.php`,
      { data }
    );
  }

  updateContractDetails(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_contract_details.php`,
      { data }
    );
  }

  updateContractBalAmount(contract_id, bookadvtamt, contact_bal_amt) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_contract_bal_amt.php`,
      { contract_id, bookadvtamt, contact_bal_amt }
    );
  }

  updateAdvtPublishDates(advt_dtl_id, advt_dates) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_advt_publishdates.php`,
      { advt_dtl_id, advt_dates }
    );
  }

  updateAdvtIssueDates(advt_dtl_id, advt_dates) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_advt_issuedates.php`,
      { advt_dtl_id, advt_dates }
    );
  }

  updateAdvtdtlStatus(advt_dtl_id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_advt_publish_status.php`,
      { advt_dtl_id }
    );
  }

  getAdvtMagNames(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_advt_magList.php`
    );
  }

  getAllIssueDates(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/get_all_Issue_Dates.php`
    );
  }

  getAdvtDatesAndNames(issueName) {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_advt_issue.php`,
      // read_advt_issue
      { issueName }
    );
  }
  addpp_pageno(req) {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/update_advt_page_no.php`,
      // read_advt_issue
      { req }
    );
  }

  getAdvtDatesAndNames1(issueName) {
    return this.httpClient.post<any[]>(
      `${this.PHP_API_SERVER}/read_advt_issue_by_issuename.php`,
      // read_advt_issue
      { issueName }
    );
  }

  getAllVouchers(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_vouchers.php`
    );
  }

  
  addDebitVoucher(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_debit_voucher.php`,
      { data }
    );
  }

  getAllCreditVouchersData(startdate, enddate, banktype) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_all_credit_vouchers_data.php`,
      {
        startdate,
        enddate,
        banktype,
      }
    );
  }

  getReconsilationReportData(startdate, enddate, banktype, cheque_no, bankfullname) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_reconsilation_bankwise_report.php`,
      {
        startdate,
        enddate,
        banktype,
        cheque_no,
        bankfullname
      }
    );
  }

  getReconsilationReportData1(startdate, enddate, banktype, cheque_no, bankfullname) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_reconsilation_bankwise_dv_report.php`,
      {
        startdate,
        enddate,
        banktype,
        cheque_no,
        bankfullname
      }
    );
  }

  updateClearingDate(id, date) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_cheque_clearing_date.php`,
      {
        id,
        date,
      }
    );
  }

  updateClearingDate1(id, date) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_cheque_clearing_date_dv.php`,
      {
        id,
        date,
      }
    );
  }

  getAllDebitVouchersData(startdate, enddate, banktype) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_all_debit_vouchers_data.php`,
      {
        startdate,
        enddate,
        banktype,
      }
    );
  }

  getAllcontra(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_bankwise_contro_bal.php`,
      {
        req
      }
    );
  }

  getAllCreditVoucherList(): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${this.PHP_API_SERVER}/read_all_credit_voucher_list.php`
    );
  }

  getDeliveryMode(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/read_delivery_mode.php`
    );
  }

  addSubDeliveryMode(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_sub_delivery_mode.php`,
      {
        data,
      }
    );
  }
  addSubDelivery(dev_mode) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_delivery_mode.php`,
      {
        dev_mode,
      }
    );
  }

  getSubDeliveryMode(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/read_sub_delivery_mode.php`
    );
  }

  getSubDistributionMode(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/read_sub_distribution_mode.php`
    );
  }

  addAssignDistributionMode(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_assign_dis_mode_to_rep.php`,
      { data }
    );
  }

  getDistributionModeRep(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_distribution_mode_rep.php`
    );
  }

  getAllDistributionModes(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_all_distribution_modes.php`
    );
  }
  getAllDistributionModes1(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_delivery_mode_data.php`
    );
  }

  getAllSubDistributionModes(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/get_all_sub_distribution_modes.php`
    );
  }

  updateAssignDistributionMode(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_distribution_mode_rep.php`,
      { data }
    );
  }

  deletedelmode(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_delivery_mode.php`,
      { id }
    );
  }

  updatedeliverymode(id, dev_mode) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_delivery_mode.php`,
      { id, dev_mode }
    );
  }

  updateAddSubDeliveryMode(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_add_sub_delivery_mode.php`,
      { data }
    );
  }

  updateAddSubDeliverySubMode(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/update_sub_delivery_of_sub_mode.php`,
      { data }
    );
  }

  deleteSubDisMode(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_sub_distribution_sub_mode.php`,
      { id }
    );
  }

  deleteDisMode(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_sub_distribution_mode.php`,
      { id }
    );
  }

  deleteAssignDisMode(id) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/delete_assign_distribution_rep.php`,
      { id }
    );
  }

  addSubModes(data) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/insert_sub_modes.php`, {
      data,
    });
  }

  getvoucherbyAdvt() {
    // console.log(dept_name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_vouchers_by_advt.php`,
      {}
    );
  }

  getvoucherbySub() {
    // console.log(dept_name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_vouchers_by_subscription.php`,
      {}
    );
  }

  getvoucherbyIssueSale() {
    // console.log(dept_name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_vouchers_by_issuesale.php`,
      {}
    );
  }

  getvoucherbybook() {
    // console.log(dept_name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_vouchers_by_book.php`,
      {}
    );
  }

  getSubIdWithMobile(mobile) {
    // console.log(mobile);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_sub_id_using_mobile.php`,
      { mobile }
    );
  }

  getBookIdWithMobile(name) {
    // console.log(name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_book_id_using_mobile.php`,
      { name }
    );
  }

  getCreditVoucherId(date, bankcashaccount) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_voucher_id.php`,
      { date, bankcashaccount }
    );
  }

  getAdvtIdWithMobile(mobile) {
    // console.log(mobile);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_id_using_mobile.php`,
      { mobile }
    );
  }

  updateVoucheritemId(id, item_id, department, voucher_no) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_voucher_item_id.php`,
      { id, item_id, department, voucher_no }
    );
  }

  insertAdvtMagazine(magazine_name) {
    // console.log(magazine_name);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_advt_mags.php`,
      { magazine_name }
    );
  }

  insertCreditVoucher(voucher_no, department, comment, amount) {
    // console.log(voucher_no, department, comment, amount);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher.php`,
      { voucher_no, department, comment, amount }
    );
  }

  insertcreditVoucher(creditvoucher) {
    // console.log(voucher_no, department, comment, amount);
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_Credit_Voucher.php`,
      { creditvoucher }
    );
  }

  updatecreditVoucher(creditvoucher) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_Credit_Voucher.php`,
      { creditvoucher }
    );
  }

  insertCrdtPackage(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/crdt_voucher_package.php`,
      {
        data,
      }
    );
  }

  updateCrdtPackage(data) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_credit_voucher_package.php`,
      { data }
    );
  }

  insertCrdtPackageDetails(data) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/add_crdt_voucher_pkg_details.php`,
      {
        data,
      }
    );
  }

  updateCrdtPackageDetails(data) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_crdt_voucher_pkg_details.php`,
      { data }
    );
  }

  insertCreditVoucherSubscribers(saib) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_saib.php`,
      { saib }
    );
  }

  updateCrdtVoucherSubcribers(saib) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_Crdt_vouchers_subscribers.php`,
      { saib }
    );
  }

  updateCrdtVoucherSubscribersNames(id, names) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_crdt_voucher_sub_names.php`,
      { id, names }
    );
  }

  insertSubCommission(com) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_sub_commission.php`,
      { com }
    );
  }

  insertIssueCommission(com) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_issue_commission.php`,
      { com }
    );
  }

  insertAdvtCommission(com) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_advt_commission.php`,
      { com }
    );
  }

  insertBookCommission(com) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_book_commission.php`,
      { com }
    );
  }

  insertCreditVoucherAdvt(saib) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_advt.php`,
      { saib }
    );
  }

  updateCreditVoucherAdvt(saib) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_crdt_voucher_advt.php`,
      { saib }
    );
  }

  updateCrdtVoucherAdvtNames(id, names) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_crdt_voucher_advt_names.php`,
      { id, names }
    );
  }

  insertCreditVoucherBook(saib) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_book.php`,
      { saib }
    );
  }

  updateCreditVoucherBook(saib) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_crdt_voucher_book.php`,
      { saib }
    );
  }

  updateCrdtVoucherBookNames(id, names) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_crdt_voucher_book_names.php`,
      { id, names }
    );
  }

  insertCreditVoucherIssueSale(saib) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_issuesale.php`,
      { saib }
    );
  }

  updateCreditVoucherIssueSale(saib) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_crdt_voucher_issue_sale.php`,
      { saib }
    );
  }

  updateCrdtVoucherIssueSaleNames(id, names) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_crdt_voucher_issue_sale_names.php`,
      { id, names }
    );
  }

  insertCreditVoucherDonationNames(credit_id, names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_donamtionNames.php`,
      { credit_id, names }
    );
  }

  updateCrdtVoucherDonationNames(id, names) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/updatecrdtvoucherDonationNames.php`,
      { id, names }
    );
  }

  insertCreditVoucherDepositsNames(credit_id, names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_depositsNames.php`,
      { credit_id, names }
    );
  }

  updateCrdtVoucherDepositsNames(id, names) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_crdt_voucher_deposits_names.php`,
      { id, names }
    );
  }

  insertCreditVoucherOtherDepositsNames(credit_id, names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_otherdepositsNames.php`,
      { credit_id, names }
    );
  }

  updateCrdtVoucherOtherDepositsNames(id, names) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_crdt_voucher_other_deposits_names.php`,
      { id, names }
    );
  }

  insertCreditVoucherSubscribersNames(id, names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_SubscribersNames.php`,
      { id, names }
    );
  }

  insertCreditVoucherAdvtNames(id, names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_AdvtNames.php`,
      { id, names }
    );
  }

  insertCreditVoucherBookNames(id, names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_BookNames.php`,
      { id, names }
    );
  }

  insertCreditVoucherIssueSaleNames(id, names) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/insert_credit_voucher_IssueSaleNames.php`,
      { id, names }
    );
  }

  updateAdvtMagDates(advt_id, advt_dates) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_advt_new_dates.php`,
      { advt_id, advt_dates }
    );
  }

  updateAdvtMagStatus(advt_id, status) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_advt_mag_status.php`,
      { advt_id, status }
    );
  }

  getAdvtBusinessReport(startdate, enddate) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_bussiness_report.php`,
      { startdate, enddate }
    );
  }

  getAdvtPrasiddhiPatrak(issue_name, issue_date) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/read_advt_prasiddhi_patrak.php`,
      { issue_name, issue_date }
    );
  }

  getPrasiddhiPatrakData(req) {
    return this.httpClient.post<any>(
      `${this.PHP_API_SERVER}/get_prasiddhi_patrak_data.php`,
      { req }
    );
  }

  updatePagenoPrasiddhiPatrak(data) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_pages_no.php`,
      { data }
    );
  }

  getPrasiddhiPatrakOff_Rep_and_advt_name() {
    return this.httpClient.get<any>(
      `${this.PHP_API_SERVER}/getPrasiddhiPatrak_Off_rep_and_advt_name.php`
    );
  }

  updateStateName(state_id, state_name) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_state_name.php`,
      { state_id, state_name }
    );
  }

  updateDistrictName(district_id, district_name, prant_id) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_district_name.php`,
      { district_id, district_name, prant_id }
    );
  }

  updateTalukaName(taluka_id, taluka_name, taluka_pincode) {
    return this.httpClient.put<any>(
      `${this.PHP_API_SERVER}/update_taluka_name.php`,
      { taluka_id, taluka_name, taluka_pincode }
    );
  }

  addCreditMainExpense(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_credit_main_expenses.php`,
      { data }
    );
  }

  otherMainExpense(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_other_main_expenses.php`,
      { data }
    );
  }

  updateCreditMainExpense(data) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_credit_main_expenses.php`,
      { data }
    );
  }

  addCreditSubExpense(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_credit_sub_expenses.php`,
      { data }
    );
  }

otherSubExpense(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_other_sub_expenses.php`,
      { data }
    );
  }

  updateCreditSubExpense(data) {
    return this.httpClient.put(
      `${this.PHP_API_SERVER}/update_credit_sub_expenses.php`,
      { data }
    );
  }

  addDebitMainExpense(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_debit_main_expenses.php`,
      { data }
    );
  }

  addDebitSubExpense(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_debit_sub_expenses.php`,
      { data }
    );
  }

  // All contract Details API
  getAllContractDetails(): Observable<any> {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/get_advt_contract_details.php`
    );
  }

  dataforrec(): Observable<any> {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/get_advt_pyt_datewise_detail.php`
    );
  }

  // All indetails contract Details API
  getAllIndetailsContractDetails(req): Observable<any> {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_indetails_contract_details.php`,
      { req }
    );
  }

  getAllDebitVocher(dv): Observable<any> {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_expense_ledger_dv_report.php`,
      { dv }
    );
  }

  getAllSubscriptiontype(cv): Observable<any> {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_ledger_for_account.php`,
      { cv }
    );
  }

  getAllCreditVocher(cv): Observable<any> {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_expense_ledger_cv_report.php`,
      { cv }
    );
  }

  getAdvtInvoiceData() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/get_advt_invoice_data.php`
    );
  }

  getAdvtInvoiceDataById(name, type) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_invoice_data_by_id.php`,
      { name , type }
    );
  }

  getentrybyrepname(name) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_payrecipt_data.php`,
      { name }
    );
  }

  getNewAdvtName() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_list.php`,
      {  }
    );
  }

  getAdvtInvoiceforAdvtNames(dates) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_names_for_invoice.php`,
      { dates }
    );
  }

  getNextAdvtInvoiceNo(date: string) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_next_advt_invoice_no.php`,
      { date }
    );
  }

  insertAdvtInvoice(data) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/add_advt_invoice.php`, {
      data,
    });
  }

  updateAdvtInvoice(data) {
    return this.httpClient.post(`${this.PHP_API_SERVER}/update_advt_invoice.php`, {
      data,
    });
  }

  getAllAdvtInvoicesList(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_all_advt_invoices_list.php`,
      req 
    );
  }
  getinvoicdedetails(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_issue_date_by_id.php`,
     {"id":id}
    );
  }

  // getAllAdvtInvoicesForPymt(name) {
  //   return this.httpClient.post(
  //     `${this.PHP_API_SERVER}/get_advt_invoices_for_pymt.php`,
  //     { name }
  //   );
  // }
  getAllAdvtInvoicesForPymt(name) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_invoices_for_pymt.php`,
      { name }
    );
  }
  getAllAdvtInvoicesForPymt1(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_invoices_for_pymt.php`,
     req
    );
  }

  getAdvtInvoiceRegisterList(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_invoice_register.php`,
      req
      // getAdvtInvoiceRegisterList
    );
  }

  cancelAdvtInvoice(id: number) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/cancel_advt_invoice.php`,
      { id }
    );
  }

  getBasicInfoOfAdvtInvoice(id: number) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_invoice_basic_details_by_Id.php`,
      { id }
    );
  }

  
  
  insertAdvtPymtRcptData(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_advt_pymt_rcpt_data.php`,
      {
        data,
      }
    );
  }

  // ********* print order start ************

  getPrintOrderData(subPeriodFromDate, subPeriodToDate, subscription_type) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_print_order_data.php`,
      { subPeriodFromDate, subPeriodToDate, subscription_type }
    );
  }

  insertPrintOrderData(data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_print_order.php`,
      { data }
    );
  }

  insertPrintOrderPrustha(order_id, data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_print_order_prustha.php`,
      { order_id, data }
    );
  }

  insertPrintOrderTypes(order_id, data) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/insert_print_order_types.php`,
      { order_id, data }
    );
  }

  getAllPrintOrderData() {
    return this.httpClient.get(
      `${this.PHP_API_SERVER}/get_all_print_order_data.php`
    );
  }

  cancelPrintOrder(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/cancel_print_order.php`,
      { req }
    );
  }

  deletePymtreceipt(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/delete_advt_py_reciept.php`,
      { req }
    );
  }

  getPrintDataByID(id: number) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_print_order_data_by_id.php`,
      {
        id,
      }
    );
  }

  getPrintOrderDataByDate(startDate, endDate) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_print_order_data_by_dates.php`,
      {
        startDate,
        endDate,
      }
    );
  }

  // ************ print order end ***************

  // ************ Javak start *******************

  // ************ Javak end *********************

  getSearchedDistrictName(districtName: string) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_search_district_name.php`,
      { districtName }
    );
  }


  

  getAdvtOfficerVb(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pyt_officer_vb.php`,
      req
    );
  }

  getAdvtBriefly(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pyt_datewise.php`,
     req
    );
  }

  getAdvtMonthwise(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pyt_monthwise.php`,
      req
    );
  }

  GSTFullReport(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_gst_register.php`,
      req
    );
  }

  getAdvtOfficerBriefly1(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pyt_officer_b.php`,
     req
    );
  }

  

  getCreditNoteVB(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_credit_officer_vb.php`,
     req
    );
  }
  getAdvtOutstanding(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_outstanding_detail.php`,
      req
    );
  }

  getPaidCommData(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_rep_commission_report.php`,
      req
    );
  }

  getPersoanlLed(req1) {
    
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_personal_leadger_detail.php`,
      req1
    );
  }
  getPersoanlLedBriefly(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_personal_leadger_briefly.php`,
      req
    );
  }
  
  practice(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_rep_commission.php`,
      req
    );
  }
  
  getCRreceipt() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_advt_credit_note.php`,
     { }
    );
  }

  getCdatabyid(req3) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_invoice_data_by_id.php`,
    req3
    );
  }

  getAdvtOutstanding1(data:any) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pyt_officer_b.php`,
      { data }
    );
  }

  getCreditNoteV(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_credit_officer_b.php`,
      req
    );
  }
  getCreditNoteM() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_credit_monthwise.php`,
      {  }
    );
  }
  getCreditNoteNB() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_credit_datewise.php`,
      {  }
    );
  }
  getOutstandingB(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_outstanding_b.php`,
      req
    );
  }
  getonlyOutstandingindetail(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_unpaid_outstanding_detail.php`,
      req
    );
  }
  creditoutstaqndingindetails(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_outstanding_detail.php`,
      req
    );
  }
  getonlyOutstandingB(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_unpaid_outstanding_b.php`,
      req
    );
  }
  
  getcreditbrieflyoutstanding(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_outstanding_b.php`,
      req
    );
  }

  getpendingreceipt(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_remainder_cus.php`,
      req
    );
  }

  getpendingreceiptRep(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_remainder_rep.php`,
      req
    );
  }
  // getPaymentRegiInDetails() {
  //   return this.httpClient.post(
  //     `${this.PHP_API_SERVER}/get_advt_pyt_datewise_detail.php`,
  //     {  }
  //   );
  // }
// get officer rep for subscr
  getOffRepSub() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_rep_list.php`,
      { }
    );
  }
  getprantname() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_all_prant.php`,
      { }
    );
  }

  getOffRepSub1() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_all_offrepname.php`,
      { }
    );
  }

  
  getSubByZilha(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_renewal_district.php`,
      req
    );
  }

  getDeliverytype(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_renewal_prant.php`,
      req
    );
  }

  getSubRemDist(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_renewable_separate_count_districtwise.php`,
      req
    );
  }

  getSubRemTaluka(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_renewable_separate_count_talukawise.php`,
      req
    );
  }
  getSubRemOffRep(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_renewable_separate_count_off_rep.php`,
      req
    );
  }

  getSubRemRep(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_renewable_separate_count_repwise.php`,
      req
    );
  }
  getSubByRep(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_renewal_rep_taluka.php`,
      req
    );
  }
  getSubByOffRep(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_renewal_off_rep_taluka.php`,
      req
    );
  }
  getSubByTypewise(req1) {
    
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_delivery_district.php`,
      req1
    );
  }

  getSubByTalukawise(req1) {
    
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_delivery_taluka.php`,
      req1
    );
  }

  getSubByoffcierwise(req1) {
    
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_delivery_off_rep_name.php`,
      req1
    );
  }

  getSubByReprwise(req1) {
    
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_delivery_rep_name.php`,
      req1
    );
  }
  
  getSubByTaluka(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_sub_renewal_taluka.php`,
      req
    );
  }
  
  getPaymentRegiInDetails1(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pyt_datewise_detail.php`,
      req
    );
  }

  
  getPaymentRegiOffInDetails(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pyt_datewaise_office_detail.php`,
      req
    );
  }
  getCreditRegiInDetails(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_datewise_detail.php`,
      req 
    );
  }
  getCreditRegiOffInDetails() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_datewaise_office_detail.php`,
      {  }
    );
  }
  getCreditRegiInBriefly() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_credit_datewise.php`,
      {  }
    );
  }
  // challandata1(req1) {
  //   return this.httpClient.post(
  //     `${this.PHP_API_SERVER}/get_price_by_issuedate.php`,
  //     { req1 }
  //   );
  // }
  challandata(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_price_by_issuedate.php`,
      { req }
    );
  }
  getgetpendingAdd(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_rep_cus_add.php`,
      req
    );
  }
  getgetAllAdd(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_cus_add.php`,
      req
    );
  }

  getNewRepList() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_rep_list.php`,
     {}
    );
  }
  withoutpagenopatrak() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_without_page_no.php`,
     {}
    );
  }
  getNewNameList() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/read_advt_names.php`,
     {  }
    );
  }
  
  getCreditNoteMain(req1) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_pay_details.php`,
    req1
    );
  }
  getStampdata(advt_name,start_date, end_date) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_advt_stamp_rcpt_list.php`,
      {advt_name,start_date, end_date}
    );
  }
  getCreditNoteModal(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/add_advt_credit_note.php`,
    req
    );
  }
  stampreceiptdelete(id) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/delete_advt_stamp_rcpt.php`,
    {id}
    );
  }
  getCreditNoteG() {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_last_credit_voc_count.php`,
     {}
    );
  }
  getAdvtStampNumber(date) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_stamp_rcpt.php`,
     {date}
    );
  }
  getCreditNoteG1(date) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_credit_note_rcpt.php`,
     {date}
    );
  }
  getReceiptnumber(req) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_last_advt_rcptno.php`,
     {req}
    );
  }
  getRenewableDataByDate(
    startDate,
    endDate,
    repName,
    offRepName,
    district,
    taluka
  ) {
    return this.httpClient.post(
      `${this.PHP_API_SERVER}/get_renewable_data_by_date.php`,
      {
        startDate,
        endDate,
        repName,
        offRepName,
        district,
        taluka,
      }
    );
  }
}
