import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-handdeliverylabelslist',
  templateUrl: './handdeliverylabelslist.component.html',
  styleUrls: ['./handdeliverylabelslist.component.scss'],
})
export class HanddeliverylabelslistComponent implements OnInit {
  labelsData;
  dis_mode;
  sub_mode;
  vitarandate;
  tempdata;
  dist_rep_name: any;
  shortName = {
    वार्षिक: 'वा.',
    त्रैवार्षिक: 'त्रै.',
    पंचवार्षिक: 'पंच.',
    लोकनेता_गंथयात्री_वार्षिक: 'लो.गं.वा.',
    लोकमान्य_ग्रंथयात्री_वार्षिक_वर्गणी: 'लो.ग्रं.वा.व.',
    हिंदुत्व_ग्रंथयात्री: 'हिं.ग्रं.',
    हिंदुत्व_2: 'हिं.2.',
    रामसेवक_ग्रंथयात्री: 'रा.ग्रं.',
  };

  constructor(
    private dialogref: MatDialogRef<HanddeliverylabelslistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    
    this.labelsData = this.data['data'].filter((a) => {
      return a['delivery_mode'] === 'हस्ते';
    });
    this.dist_rep_name = this.data.dist_rep_name
    this.dis_mode = this.data['dis_mode'];
    this.sub_mode = this.data['mode'];
    this.vitarandate = this.data['vitarandate'];

    console.log(this.data['data'], "dis data");
    

    this.labelsData.map((a) => {
      a.shortname = this.shortName[a.Subscription_Type.split(' ').join('_')];
    });

    // this.tempdata = this.uniqueid(this.labelsData, (a) => a['rep_name']);
    // console.log(this.tempdata);
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
}
