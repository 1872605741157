<mat-card style="margin-top: 20px; border-radius: 10px; padding-bottom: 30px">
  <h2 style="text-align: center">Update Subscription Type</h2>
  <form [formGroup]="subtypeform" (ngSubmit)="updatesubtype()">
    <div class="form-row">
      <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 7%; width: 35%">
        <mat-label>Subscription Type</mat-label>
        <input matInput formControlName="subtype" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%">
        <mat-label>Price</mat-label>
        <input matInput type="number" formControlName="price" />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 7%; width: 35%">
        <mat-label>Date</mat-label>
        <input matInput type="date" formControlName="date" required />
      </mat-form-field>
    </div>
    <div class="form-group">
      <button style="margin-left: 15%; margin-right: 15%; width: 30%" [disabled]="subtypeform.invalid" type="submit"
        mat-raised-button color="primary" class="btn btn-primary btn-block">
        Update
      </button>
      <button mat-dialog-close mat-button style="margin-left: 2%">Close</button>
    </div>
  </form>
</mat-card>