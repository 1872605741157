import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-address-labels',
  templateUrl: './address-labels.component.html',
  styleUrls: ['./address-labels.component.scss'],
})
export class AddressLabelsComponent implements OnInit {
  labelsData = [];
  tempdata = [];
  data1 = [];
  today = new Date();
  repNames = '';
  deliveryMode = '';
  firstRepName = '';
  lastRepName = '';
  district = '';
  state = '';
  taluka = '';

  shortName = {
    वार्षिक: 'वा.',
    त्रैवार्षिक: 'त्रै.',
    पंचवार्षिक: 'पंच.',
    लोकनेता_गंथयात्री_वार्षिक: 'लो.गं.वा.',
    लोकमान्य_ग्रंथयात्री_वार्षिक_वर्गणी: 'लो.ग्रं.वा.व.',
    हिंदुत्व_ग्रंथयात्री: 'हिं.ग्रं.',
    हिंदुत्व_2: 'हिं.2.',
    रामसेवक_ग्रंथयात्री: 'रा.ग्रं.',
  };
  iscustomer: any;

  constructor(
    private dialogref: MatDialogRef<AddressLabelsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.labelsData = this.data['addresslabels'];
    this.repNames = this.data['rep_names'];
    this.deliveryMode = this.data['delivery_mode'];
    console.log(this.repNames);
    if (this.repNames.length > 0) {
      this.firstRepName =
        this.repNames[0] == 'all' ? this.repNames[1] : this.repNames[0];
      this.lastRepName = this.repNames[this.repNames.length - 1];
    }
    this.district = this.data['district_name'];
    this.state = this.data['state_name'];
    this.taluka = this.data['taluka_name'];
    this.iscustomer = this.data['iscustomer'];


    this.tempdata = this.uniqueid(this.labelsData, (a) => a['district']);
    // console.log(this.tempdata);

    this.sortDataDistrictwise();
  }

  sortDataDistrictwise() {
    this.labelsData.sort((a, b) => Number(a.pincode) - Number(b.pincode));
    // this.tempdata.sort((a, b) => a.name.localeCompare(b.name, ['en', 'mr']));
    for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
      let data = this.labelsData.filter(
        (a) => a['district'] == this.tempdata[index]['district']
      );

      data.map((a) => {
        a.shortname = this.shortName[a.Subscription_Type.split(' ').join('_')];
      });

      // this.tempdata.push({ index: data });

      // data.sort((a, b) => {
      //   if (a.name < b.name) {
      //     return -1;
      //   } else if (a.name > b.name) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
     
      this.data1.push(data);
    }

   
    // console.log(this.data1);
    // console.log(this.tempdata);
    let data=[]
    this.data1.forEach(element => {
      element.forEach(data2 => {
        data.push(data2)
      });
      
    });
    this.data1=data
// let currentPage=1
// let printData:any=[]
//     for (let i = 0; i < this.data1.length; i++) {
//       const item = this.data1[i];
//       if (i % 28 === 0 && i !== 0) {
//         currentPage++;
//       }
//       printData.push({ ...item, pageNumber: currentPage });
//     }
//     this.data1=printData
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
  getPageNumber(index: number): number {
    return Math.floor(index / 28) + 1;
  }
  print() {
    document.title = 'Labels';
    window.print();
  }

  // getRep(rep, pos) {
  //   console.log(
  //     rep.split(',')[pos === 'first' ? 0 : rep.split(',').length - 1]
  //   );

  //   return ;
  // }
}
