import { formatDate } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-advt-invoice-register',
  templateUrl: './advt-invoice-register.component.html',
  styleUrls: ['./advt-invoice-register.component.scss'],
})
export class AdvtInvoiceRegisterComponent implements OnInit {
  startDate;
  endDate;
  today;
  issueName;
  issueDates;
  reportType;
  advtInvoiceData;
  filteredAdvtInvoiceData : any;
  isLoading = false;
  printShowData = [];
  filteredPrintShowData = [];
  sdate: any;
  enddate: any;
  invoiceNumber: any;
  issuedate: any;
  invoiceName: any;
  advtamount: number;
  discountamt: number;
  netamount: number;
  gstcst: number;
  totalinvoiceamount: number;
  paidamount: number;

  constructor(
    private dialogRef: MatDialogRef<AdvtInvoiceRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    
    this.isLoading = false;
    this.today = new Date();
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.issueName = this.data['issueName'];
    this.issueDates = this.data['issueDates'];
    this.reportType = this.data['reportType'];
    this.sdate = this.data['startDate'];
    this.enddate = this.data['endDate'];
    this.invoiceName = this.data['issueName'];
    this.issuedate = this.data['issueDates'];
    // this.advtInvoiceData = this.data['advtData'];
    // console.log(this.data);

    console.log(this.sdate, this.enddate, this.invoiceName, this.issuedate, "data");

    this.getData();
  }

  getData() {
    

    const req:any = {
      "start_date":this.sdate,
      "end_date":this.enddate,
      "issue_name":this.invoiceName,
      "issue_date":this.issuedate,
      
    }
    this.service.getAdvtInvoiceRegisterList(req).subscribe((res: any) => {
      console.log(res);
      this.filteredAdvtInvoiceData = res;

      this.advtamount =0;
      this.discountamt =0;
      this.netamount =0;
      this.gstcst =0;
      this.totalinvoiceamount =0;
      this.paidamount =0;
      // this.advtamount =0;


      for (let data1 of this.filteredAdvtInvoiceData) {
        this.advtamount += parseInt(data1.advt_amt) || 0;
        this.discountamt += parseInt(data1.discount) || 0;
        this.netamount += parseInt(data1.net_amt) || 0;
        this.gstcst += parseInt(data1.gst) || 0;
        this.totalinvoiceamount += parseInt(data1.amount) || 0;
        this.paidamount += parseInt(data1.received_amt) || 0;
        // this.paidamount += parseInt(data1.received_amt) || 0;
    }


      console.log("inovice register data", this.filteredAdvtInvoiceData)

     
        // for (let data1 of this.filteredAdvtInvoiceData) {
        //     this.CredittadvtInBrieflyTotal += parseInt(data1.rec_amt) || 0;
        // }
    
      // setTimeout(() => {
      //   this.setData();
      // }, 700);
      // this.filterData();
    });
  }

  filterData() {
    // console.log('called');
    if (this.startDate == '' && this.endDate == '') {
      this.advtInvoiceData = this.filteredAdvtInvoiceData;
      // console.log('filteredData', this.advtInvoiceData);
    } else if (this.startDate !== '' && this.endDate !== '') {
      this.advtInvoiceData = this.filteredAdvtInvoiceData.filter((a) => {
        return (
          a['invoice_date'] >= this.startDate &&
          a['invoice_date'] <= this.endDate
        );
      });
      // console.log('filteredData', this.advtInvoiceData);
    }

    setTimeout(() => {
      this.setData();
    }, 700);
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  setData() {
    // console.log(this.advtInvoiceData);
    // console.log(this.advtInvoiceData);
    if (this.advtInvoiceData.length == 0) {
      this.isLoading = false;
      alert('Data is not Present.');
      this.dialogRef.close();
    }
    let data = this.uniqueid(this.advtInvoiceData, (a) => a.advt_name);
    // console.log(data);
    for (let index = 0; index < this.advtInvoiceData.length; index++) {
      // let eachData = this.advtInvoiceData.filter(
      //   (a) => data[i]['advt_name'] == a.advt_name
      // );

      let showData = {
        issue_name: '',
        invoice_no: '',
        invoice_date: '',
        advt_name: '',
        client_name: '',
        rep_name: '',
        off_rep_name: '',
        firstweek_issue_date: '',
        secondweek_issue_date: '',
        thirdweek_issue_date: '',
        fourthweek_issue_date: '',
        fifthweek_issue_date: '',
        total_advt_amt: 0,
        total_dis: 0,
        total_net: 0,
        total_cgst: 0,
        total_sgst: 0,
        total_igst: 0,
        total_amt: 0,
      };

      // if (Object.keys(eachData).length > 0) {
      console.log('called', this.advtInvoiceData);
      // for (let index = 0; index < this.advtInvoiceData.length; index++) {
      if (
        this.advtInvoiceData[index]['first_week_id'] != null &&
        this.advtInvoiceData[index]['first_week_issue_name'] ===
          this.issueName &&
        this.issueDates.includes(
          this.advtInvoiceData[index]['first_week_issue_date']
        )
      ) {
        // console.log('first');
        showData['issue_name'] =
          this.advtInvoiceData[index]['first_week_issue_date'].spilt(',')[1];
          console.log(  showData['issue_name'], "new issue name");

        showData['firstweek_issue_date'] =
          this.advtInvoiceData[index]['first_week_issue_date'].split(',')[0];
        showData['total_advt_amt'] += parseInt(
          this.advtInvoiceData[index]['first_week_advt_amt']
        );
        showData['total_dis'] += parseInt(
          this.advtInvoiceData[index]['first_week_advt_discnt']
        );
        showData['total_net'] += parseInt(
          this.advtInvoiceData[index]['first_week_amount']
        );
        showData['total_cgst'] += parseInt(
          this.advtInvoiceData[index]['first_week_cgst']
        );
        showData['total_sgst'] += parseInt(
          this.advtInvoiceData[index]['first_week_sgst']
        );
        showData['total_igst'] += parseInt(
          this.advtInvoiceData[index]['first_week_igst']
        );
        showData['total_amt'] += parseInt(
          this.advtInvoiceData[index]['first_week_total_amount']
        );
      } else if (
        this.advtInvoiceData[index]['second_week_id'] != null &&
        this.advtInvoiceData[index]['second_week_issue_name'] ===
          this.issueName &&
        this.issueDates.includes(
          this.advtInvoiceData[index]['second_week_issue_date']
        )
      ) {
        showData['issue_name'] =
          this.advtInvoiceData[index]['first_week_issue_date'].spilt(',')[1];
        showData['secondweek_issue_date'] =
          this.advtInvoiceData[index]['second_week_issue_date'].split(',')[0];
        showData['total_advt_amt'] += parseInt(
          this.advtInvoiceData[index]['second_week_advt_amt']
        );
        showData['total_dis'] += parseInt(
          this.advtInvoiceData[index]['second_week_advt_discnt']
        );
        showData['total_net'] += parseInt(
          this.advtInvoiceData[index]['second_week_amount']
        );
        showData['total_cgst'] += parseInt(
          this.advtInvoiceData[index]['second_week_cgst']
        );
        showData['total_sgst'] += parseInt(
          this.advtInvoiceData[index]['second_week_sgst']
        );
        showData['total_igst'] += parseInt(
          this.advtInvoiceData[index]['second_week_igst']
        );
        showData['total_amt'] += parseInt(
          this.advtInvoiceData[index]['second_week_total_amount']
        );
      } else if (
        this.advtInvoiceData[index]['third_week_id'] != null &&
        this.advtInvoiceData[index]['third_week_issue_name'] ===
          this.issueName &&
        this.issueDates.includes(
          this.advtInvoiceData[index]['third_week_issue_date']
        )
      ) {
        showData['issue_name'] =
          this.advtInvoiceData[index]['first_week_issue_date'].spilt(',')[1];
        showData['thirdweek_issue_date'] =
          this.advtInvoiceData[index]['third_week_issue_date'].split(',')[0];
        showData['total_advt_amt'] += parseInt(
          this.advtInvoiceData[index]['third_week_advt_amt']
        );
        showData['total_dis'] += parseInt(
          this.advtInvoiceData[index]['third_week_advt_discnt']
        );
        showData['total_net'] += parseInt(
          this.advtInvoiceData[index]['third_week_amount']
        );
        showData['total_cgst'] += parseInt(
          this.advtInvoiceData[index]['third_week_cgst']
        );
        showData['total_sgst'] += parseInt(
          this.advtInvoiceData[index]['third_week_sgst']
        );
        showData['total_igst'] += parseInt(
          this.advtInvoiceData[index]['third_week_igst']
        );
        showData['total_amt'] += parseInt(
          this.advtInvoiceData[index]['third_week_total_amount']
        );
      } else if (
        this.advtInvoiceData[index]['fourth_week_id'] != null &&
        this.advtInvoiceData[index]['fourth_week_issue_name'] ===
          this.issueName &&
        this.issueDates.includes(
          this.advtInvoiceData[index]['fourth_week_issue_date']
        )
      ) {
        showData['issue_name'] =
          this.advtInvoiceData[index]['first_week_issue_date'].spilt(',')[1];
        showData['fourthweek_issue_date'] =
          this.advtInvoiceData[index]['fourth_week_issue_date'].split(',')[0];
        showData['total_advt_amt'] += parseInt(
          this.advtInvoiceData[index]['fourth_week_advt_amt']
        );
        showData['total_dis'] += parseInt(
          this.advtInvoiceData[index]['fourth_week_advt_discnt']
        );
        showData['total_net'] += parseInt(
          this.advtInvoiceData[index]['fourth_week_amount']
        );
        showData['total_cgst'] += parseInt(
          this.advtInvoiceData[index]['fourth_week_cgst']
        );
        showData['total_sgst'] += parseInt(
          this.advtInvoiceData[index]['fourth_week_sgst']
        );
        showData['total_igst'] += parseInt(
          this.advtInvoiceData[index]['fourth_week_igst']
        );
        showData['total_amt'] += parseInt(
          this.advtInvoiceData[index]['fourth_week_total_amount']
        );
      } else if (
        this.advtInvoiceData[index]['fifth_week_id'] != null &&
        this.advtInvoiceData[index]['fifth_week_issue_name'] ===
          this.issueName &&
        this.issueDates.includes(
          this.advtInvoiceData[index]['fifth_week_issue_date']
        )
      ) {
        showData['issue_name'] =
          this.advtInvoiceData[index]['first_week_issue_date'].spilt(',')[1];
        showData['fifthweek_issue_date'] =
          this.advtInvoiceData[index]['fifth_week_issue_date'].split(',')[0];
        showData['total_advt_amt'] += parseInt(
          this.advtInvoiceData[index]['fifth_week_advt_amt']
        );
        showData['total_dis'] += parseInt(
          this.advtInvoiceData[index]['fifth_week_advt_discnt']
        );
        showData['total_net'] += parseInt(
          this.advtInvoiceData[index]['fifth_week_amount']
        );
        showData['total_cgst'] += parseInt(
          this.advtInvoiceData[index]['fifth_week_cgst']
        );
        showData['total_sgst'] += parseInt(
          this.advtInvoiceData[index]['fifth_week_sgst']
        );
        showData['total_igst'] += parseInt(
          this.advtInvoiceData[index]['fifth_week_igst']
        );
        showData['total_amt'] += parseInt(
          this.advtInvoiceData[index]['fifth_week_total_amount']
        );
      }
      // }
      if (
        (this.advtInvoiceData[index]['first_week_issue_name'] ===
          this.issueName &&
          this.issueDates.includes(
            this.advtInvoiceData[index]['first_week_issue_date']
          )) ||
        (this.advtInvoiceData[index]['second_week_issue_name'] ===
          this.issueName &&
          this.issueDates.includes(
            this.advtInvoiceData[index]['second_week_issue_date']
          )) ||
        (this.advtInvoiceData[index]['third_week_issue_name'] ===
          this.issueName &&
          this.issueDates.includes(
            this.advtInvoiceData[index]['third_week_issue_date']
          )) ||
        (this.advtInvoiceData[index]['fourth_week_issue_name'] ===
          this.issueName &&
          this.issueDates.includes(
            this.advtInvoiceData[index]['fourth_week_issue_date']
          )) ||
        (this.advtInvoiceData[index]['fifth_week_issue_name'] ===
          this.issueName &&
          this.issueDates.includes(
            this.advtInvoiceData[index]['fifth_week_issue_date']
          ))
      ) {
        showData['invoice_no'] = this.advtInvoiceData[0]['invoice_no'];
        showData['invoice_date'] = this.advtInvoiceData[0]['invoice_date'];
        showData['advt_name'] = this.advtInvoiceData[0]['advt_name'];
        showData['client_name'] = this.advtInvoiceData[0]['cust_name'];
        showData['rep_name'] = this.advtInvoiceData[0]['rep_name'];
        showData['off_rep_name'] = this.advtInvoiceData[0]['off_rep_name'];

        this.printShowData.push(showData);
      }

      // console.log('showData', showData);
      // }
      console.log('printShowData', this.printShowData);
      if (index == data.length - 1) {
        this.isLoading = false;
        this.filterPrintShowData();
      }
    }
  }

  filterPrintShowData() {
    let data = this.uniqueid(this.printShowData, (a) =>
      formatDate(a.invoice_date, 'MM', 'en-IN', 'GMT+0530')
    );
    // console.log(data);
    let totalAdvtAmt1 = 0;
    let totalDisAmt1 = 0;
    let totalNetAmt1 = 0;
    let totalCGST1 = 0;
    let totalSGST1 = 0;
    let totalIGST1 = 0;
    let totalInvoiceAmount1 = 0;
    for (let index = 0; index < data.length; index++) {
      let filteredData = this.printShowData.filter((a) => {
        return (
          formatDate(a.invoice_date, 'MM', 'en-IN', 'GMT+0530') ==
          formatDate(data[index]['invoice_date'], 'MM', 'en-IN', 'GMT+0530')
        );
      });
      // console.log(filteredData);
      let totalAdvtAmt = 0;
      let totalDisAmt = 0;
      let totalNetAmt = 0;
      let totalCGST = 0;
      let totalSGST = 0;
      let totalIGST = 0;
      let totalInvoiceAmount = 0;
      let issue_date = '';
      for (let i = 0; i < filteredData.length; i++) {
        totalAdvtAmt += parseInt(filteredData[index]['total_advt_amt']);
        totalDisAmt += parseInt(filteredData[index]['total_dis']);
        totalNetAmt += parseInt(filteredData[index]['total_net']);
        totalCGST += parseInt(filteredData[index]['total_cgst']);
        totalSGST += parseInt(filteredData[index]['total_sgst']);
        totalIGST += parseInt(filteredData[index]['total_igst']);
        totalInvoiceAmount += parseInt(filteredData[index]['total_amt']);
        issue_date = filteredData[index]['invoice_date'];
      }

      filteredData.push({
        issue_date,
        totalAdvtAmt,
        totalDisAmt,
        totalNetAmt,
        totalCGST,
        totalSGST,
        totalIGST,
        totalInvoiceAmount,
      });

      totalAdvtAmt1 += totalAdvtAmt;
      totalDisAmt1 += totalDisAmt;
      totalNetAmt1 += totalNetAmt;
      totalCGST1 += totalCGST;
      totalSGST1 += totalSGST;
      totalIGST1 += totalIGST;
      totalInvoiceAmount1 = totalInvoiceAmount;

      this.filteredPrintShowData.push(filteredData);
    }

    this.filteredPrintShowData.push({
      totalAdvtAmt1,
      totalDisAmt1,
      totalNetAmt1,
      totalCGST1,
      totalSGST1,
      totalIGST1,
      totalInvoiceAmount1,
    });

    console.log(this.filteredPrintShowData);
  }

  exportExcel(fileName) {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  issuedatereturn(dates){
    if(dates!=null){
      let returnissuedate = dates.split(',');
      return returnissuedate[0];
    }else{
      return null;
    }
  
  }
}
