import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-districtwise-statement',
  templateUrl: './districtwise-statement.component.html',
  styleUrls: ['./districtwise-statement.component.scss'],
})
export class DistrictwiseStatementComponent implements OnInit {
  districtwiseData;
  sundayankDate;
  dispatchankDate;
  total_sub_count = 0;
  totalparcel = 0;
  totalanksankhya = 0;
  filtereddistrictwisesubcout = [];
  disRepData = [];

  constructor(
    private dialogref: MatDialogRef<DistrictwiseStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    this.service
      .getDistributionRepresentativeSBhetHastewise()
      .subscribe((res) => {
        this.disRepData = res;
        // console.log(this.disRepData);
      });

    this.districtwiseData = this.data['data'];
    this.sundayankDate = this.data['sundaydate'];
    this.dispatchankDate = this.data['dispatchdate'];
    // console.log(this.districtwiseData);

    //hold on for while
    // let checkData = this.uniqueid(
    //   this.districtwiseData,
    //   (a) => a['distribution_rep']
    // );

    this.total_sub_count = 0;
    this.totalparcel = 0;
    this.totalanksankhya = 0;
    let tempdata = this.uniqueid(this.districtwiseData, (a) => a['district']);

    this.filtereddistrictwisesubcout = [];
    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.districtwiseData.filter(
        (a) => a['district'] == tempdata[index]['district']
      );

      let delivery_mode_unique = this.uniqueid(arr, (a) => a['delivery_mode']);
      // console.log({ delivery_mode_unique });

      let totalTapalCount = 0;
      let parcelval = 0;
      let anksankhya = 0;
      for (let j = 0; j < Object.keys(delivery_mode_unique).length; j++) {
        let arr1 = arr.filter(
          (a) => a['delivery_mode'] == delivery_mode_unique[j]['delivery_mode']
        );

        let hasteTapalJPO = [];
        if (arr1[0]['delivery_mode'] === 'टपाल') {
          totalTapalCount = Object.keys(arr1).length;
          // console.log({ totalTapalCount });
        } else if (arr1[0]['delivery_mode'] === 'हस्ते') {
          hasteTapalJPO = arr1.filter(
            (a) => a['dis_mode'] == 'टपाल' && a['dis_sub_mode'] == 'जी.पी.ओ'
          );

          if (hasteTapalJPO.length > 0) {
            let hasteTapalJPOUnique = this.uniqueid(
              hasteTapalJPO,
              (a) => a['distribution_rep']
            );
            // console.log({ hasteTapalJPOUnique });
            parcelval = hasteTapalJPOUnique.length;
            hasteTapalJPOUnique.map((tapal) => {
              let ank = hasteTapalJPO.filter(
                (a) => a['distribution_rep'] === tapal['distribution_rep']
              );
              // console.log({ ank });
              anksankhya += ank.length;
            });
          }
        }
      }

      this.filtereddistrictwisesubcout.push({
        name: tempdata[index]['name'],
        Subscription_Type: tempdata[index]['Subscription_Type'],
        address: tempdata[index]['address'],
        country: tempdata[index]['country'],
        delivery_mode: tempdata[index]['delivery_mode'],
        district: tempdata[index]['district'],
        pincode: tempdata[index]['pincode'],
        state: tempdata[index]['state'],
        sub_perid_to_date: tempdata[index]['sub_perid_to_date'],
        taluka: tempdata[index]['taluka'],
        sub: totalTapalCount,
        parcel: `${parcelval}=${anksankhya}`,
      });

      this.total_sub_count += totalTapalCount;
      this.totalparcel += parcelval;
      this.totalanksankhya += anksankhya;
    }
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
}
