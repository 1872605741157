<div >
    <div class="container mb-5" style="border: 2px solid black">
        <div class="row">
            <div class="col-3 image">
                <img style="margin-top: 8%" width="90%" src="../../../assets/img/brand/red.png" />
            </div>
            <div class="col-7" style="padding-top: 30px; text-align: center; padding-bottom: 10px">
                <span style="font-size: 30px; font-weight: bold">हिंदुस्थान प्रकाशन संस्था</span>
                <span style="font-size: 14px; margin-left: 05px">(Reg.No.-BOM-3/1961(GBBSD))</span>
                <hr />
                <div class="row" style="text-align: left">
                    <div class="col-6" style="padding-top: 10px">
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">प्रशासकीय
                            कार्यालय : <br></span>
                            116, 117, 118, 119, <br> पहिला मजला, शिल्पीन सेंटर, ग. द. आंबेकर मार्ग, <br> वडाळा , मुंबई - 400031<br /></span>
                        <span style="font-size: 16px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी :</span>
                            9967570531
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स:</span>
                            27810237 -->
                        </span>
                    </div>
                    <div class="col-6" style="
              border-style: solid;
              border-width: 0px 0px 0px 2px;
              padding-top: 10px;
            ">
                        <span style="font-size: 14px"><span style="font-weight: bold; font-size: 16px">मुख्य कार्यालय : <br>
                        </span>
                        5/12, कामत औद्योगिक वसाहत, <br>
                        396 स्वा. वीर सावरकर मार्ग, <br> प्रभादेवी,  मुंबई 400025.<br /></span>
                        <span style="font-size: 16px"><span style="font-weight: bold; font-size: 16px">दूरध्वनी :
                            </span>
                            24221440
                            <!-- <span style="font-weight: bold; font-size: 16px">फॅक्स : </span>
                            24363756 -->
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-2" style="
          border-style: solid;
          border-width: 0px 0px 0px 2px;
          /* padding-top: 40px; */
          text-align: center;
          display: flex;
          align-items: center;
        ">
                    <span style="font-size: 24px">जाहिरात जमा <br> <br> पावती</span>
                </div>
        </div>
        <div class="row" style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
      ">
            <div class="col-6" style="padding-top: 20px; text-align: left">
                <span style="font-weight: bold; font-size: 16px">Advertieser Name :&nbsp;</span> <span  style="font-size: 14px">{{
                    advtInvoicePricePerIssue[0]["advt_name"]
                    }}</span><br />
                <span style="font-weight: bold; font-size: 16px">Address :&nbsp;</span><span style="font-size: 14px">{{this.address}} {{this.taluka}} {{this.dist}} {{this.state}} {{this.pincode}}</span><br />
                <span style="font-weight: bold; font-size: 16px">Mobile No :&nbsp;</span><span
                    style="font-size: 14px">{{this.mobile}}</span>
            </div>
            <div class="col-6" style="border-style: solid; border-width: 0px 0px 0px 2px">
                <div class="row" style="
            border-style: solid;
            border-width: 0px 0px 2px 0px;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 10px;
          ">
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">पावती क्र.: </span><span>{{ this.receiptno
                            }}</span>
                    </div>
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">दिनांक :&nbsp;</span><span
                            style="font-size: 14px">{{
                            this.date | date : "dd-MM-yyy"
                            }}</span>
                    </div>
                </div>
                <div class="row" style="padding-top: 20px">
                    <div class="col-12" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">यांच्याकडुन रुपये&nbsp;</span>
                        <span style="font-size: 14px">{{ this.totalinWords }} /-</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: right">
                        <span style="font-weight: bold; font-size: 16px">मात्र साभार पोहोचले.</span>
                    </div>
                </div>
                <div class="row" style="padding-top: 05px">
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">धनादेश क्र:&nbsp;</span><span
                            style="font-size: 14px">{{
                            this.pendingAdvtData["cheque_no"]
                            }}</span>
                    </div>
                    <div class="col-6" style="text-align: left">
                        <span style="font-weight: bold; font-size: 16px">दिनांक :&nbsp;</span>
                        <span style="font-size: 14px">{{
                            this.data["recptdate"] | date : "dd-MM-yyy"
                            }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 05px">
                        <span style="font-weight: bold; font-size: 16px">बँक :&nbsp;</span>
                        <span style="font-size: 14px">{{
                            this.pendingAdvtData["branch_name"]
                            }}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 05px; padding-bottom: 10px">
                        <span style="font-weight: bold; font-size: 16px">जमा रक्कम रु :&nbsp;</span><span
                            style="font-size: 14px">{{ this.value }} /-</span>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12" style="text-align: left; padding-top: 05px; padding-bottom: 10px">
                        <span style="font-weight: bold; font-size: 16px">अक्षरी रक्कम :&nbsp;</span><span
                            style="font-size: 14px">{{ this.totalinWords }}</span>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="row" style="
        border-style: solid;
        border-width: 2px 0px 0px 0px;
        text-align: center;
        padding-top: 20px;
      ">
            <div class="col-12" style="text-align: left">
                <span style="font-weight: bold; font-size: 16px">बिल नंबर: </span>
                <span style="font-size: 14px" *ngFor="let book of advtInvoicePricePerIssue">{{ book["invoice_no"] }},
                    &nbsp;</span>
            </div>
        </div>
        <div class="row" style="margin-top: 80px; padding-bottom: 10px">
            <div class="col-6">
                <span style="font-weight: bold; font-size: 16px">(धनादेश वटल्यावरच पावती अधिकृत होईल)</span>
            </div>
            <div class="col-6" style="text-align: right; font-weight: bold; font-size: 16px">
                <span>व्यवस्थापक / लेखापाल</span>
            </div>
        </div>
    </div>
    <button class="noPrint" style="margin-top: 2%" (click)="save()" mat-raised-button color="primary">
        Save
    </button>
    <button class="noPrint" style="margin-top: 2%; margin-left: 10px" (click)="print()" mat-raised-button
        color="primary">
        Print
    </button>
    <button class="noPrint" style="margin-top: 2%; margin-left: 10px" mat-button mat-dialog-close>
        Close
    </button>
</div>