import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContractBriefReportComponent } from '../pages/contract-brief-report/contract-brief-report.component';
import { DigisubservicesService } from '../services/digisubservices.service';
import { AdvertisementDetailsComponent } from '../pages/advertisement-details/advertisement-details.component';
import { ToWords } from 'to-words';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';



@Component({
  selector: 'app-stampreceipt',
 
  templateUrl: './stampreceipt.component.html',
  styleUrls: ['./stampreceipt.component.scss']
})
export class StampreceiptComponent {
  stampdata: any;
  total: any;
  invoicebillnumber: any="";
  advtname : any
  advncestampnum : any
  AdvtSnum :any
  date1 : any
  toWords: ToWords;
  totalinWords: string;
  currentDate: any;
  address: any;
  mobile: any;
  creation_date:any
  rep_name:any
  stamp_number:any
  current_date:any
  

  constructor(private dialogRef: MatDialogRef<StampreceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder,
    private datePipe : DatePipe,
    private toaster: ToastrService,

  )
   { }

   ngOnInit(): void {
    
    
    this.stampdata = this.data['stampdata'];
    // this.total = this.data['stampdata']['rcv_amt'];
    this.total = this.data.stampdata[0].rcv_amt;
    this.address = this.stampdata[0]['address']
    this.mobile = this.stampdata[0]['mobile']
    this.creation_date = this.data.creation_date
    this.rep_name = this.data.rep_name
    this.stamp_number = this.data.stamp_number
    this.current_date = this.data.current_date

    
    this.AdvtSnum = this.data['advancenum'];
    this.currentDate = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
   

    this.stampdata.forEach(element => {
       this.invoicebillnumber = this.invoicebillnumber + "," + element.invoice_no;
       this.advtname = element.advt_name;
       this.date1 = element.invoice_date;

      //  this.advncestampnum = element.
      
    });

     this.toWords = new ToWords();
        this.totalinWords = this.toWords.convert(this.total, {
          currency: true,
        });
    console.log(this.date1 , this.invoicebillnumber)
   }

   save(){
    const data:any = {
      "creation_date":this.current_date,
      "receipt_date":this.data.creation_date,
      "rep_name":this.rep_name,
      "receipt_no":this.stamp_number,
      "amt": this.total,
      "advt_name":this.stampdata[0].advt_name,
    }
   

    this.service.insertStampReceipt(data).subscribe((res)=>{
    })
    this.toaster.success('success', 'Stamp Receipt Creted Sucessfully !');

    this.dialogRef.close()
    this.ngOnInit()

    console.log(data, "list")
   }


}
