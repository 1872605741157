import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';
import { PrintOptions } from 'ngx-print';
// import { resolve } from 'dns';

@Component({
  selector: 'app-expenseledger',
  templateUrl: './expenseledger.component.html',
  styleUrls: ['./expenseledger.component.scss']
})
export class ExpenseledgerComponent {
  printOptions: PrintOptions = {
    printSectionId: 'print-section',
    printTitle: 'My Print Title',
    useExistingCss: true,
    bodyClass: 'print-body',
    openNewTab: true,
    previewOnly: false,
    closeWindow: false,
    printDelay: 500
  };
  currentDate: Date;
  startDate: any;
  endDate: any;
  mainexp: any;
  subexp: any;
  alldebitvoucher: any;
  allcreditvoucher: any;
  allvoucherdata: any = [];
  credittotal: number=0;
  debittotal: number=0;
  expname: any;
  cr_opening_bal : any;
  dr_opening_bal : any;

  constructor(
    private datePipe: DatePipe,
    private dialogRef: MatDialogRef<ExpenseledgerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
  ) {

  }
  getFormattedDate(): string {
    return this.datePipe.transform(this.currentDate, 'dd-MM-yyyy');
  }


  ngOnInit(): void {

    Promise.all([
      new Promise<void>((resolve) => {
        this.currentDate = new Date();
        this.startDate = this.data['startDate'];
        this.endDate = this.data['endDate'];
        this.mainexp = this.data['mainexp'];
        this.subexp = this.data['subexp'];
        console.log(this.startDate, this.endDate, this.mainexp, this.subexp);
        resolve(); 
      }),
      ...(this.mainexp == '30' || this.mainexp == '38' || this.mainexp == '39' || this.mainexp == '60'? [this.subscriptiontype()] :[this.getdebitvocher(), this.getcreditvocher()])


    ]).then(() => {
        console.log('All promises resolved successfully');
        this.calculation()
        this.expname=this.allvoucherdata[0]['exp_name'];

      

        this.allvoucherdata = this.allvoucherdata.sort((a, b) => {
          return new Date(a.voucher_date).getTime() - new Date(b.voucher_date).getTime();
      });

      this.allvoucherdata.forEach((element, index) => {
        if (index > 0) {
          

          if(this.mainexp == '30' || this.mainexp == '38' || this.mainexp == '39' || this.mainexp == '60'){
            if (element.voucher == 'cr') {
              element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
            } else {
              element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
            }
          }else{
            if (element.voucher == 'cr') {
              element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
            } else {
              element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
            }
          }
       
        } else {
          element.total = this.allvoucherdata[0].exp_amt
        }
      });
        console.log(this.allvoucherdata)

      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  getdebitvocher(): Promise<any> {
    return new Promise((resolve: any, reject) => {
      const dv: any = {
        "exp_id": this.mainexp,
        "subexp_id": this.subexp,
        "startdate": this.startDate,
        "enddate": this.endDate,

      }
      this.service.getAllDebitVocher(dv).subscribe((a) => {
        // console.log(a);
        // this.alldebitvoucher = a;
        let debitlist = a
        debitlist.forEach(element => {
          this.allvoucherdata.push(element);

        });

        this.dr_opening_bal = debitlist[0].opening_bal_dr


        console.log(this.expname, "contract deatail dv");
      });

      setTimeout(() => {
        resolve();
      }, 1000);
    })

    

  }

  getcreditvocher() {

    return new Promise((resolve: any, reject) => {
      const cv: any = {
        "exp_id": this.mainexp,
        "subexp_id": this.subexp,
        "startdate": this.startDate,
        "enddate": this.endDate,
      }
      this.service.getAllCreditVocher(cv).subscribe((a) => {
        // console.log(a);
        let creditlist = a;
        // this.allvoucherdata.push(a);
        creditlist.forEach(element => {
          this.allvoucherdata.push(element);
        });
        this.cr_opening_bal = creditlist[0].opening_bal_cr
        console.log(this.allvoucherdata, "contract deatail cv");
      });

      setTimeout(() => {
        resolve();
      }, 1000);
    })


  }

  subscriptiontype(): Promise<any> {
    return new Promise((resolve: any, reject) => {
      const cv: any = {
        "exp_id": this.mainexp,
        "subexp_id": this.subexp,
        "startdate": this.startDate,
        "enddate": this.endDate,

      }
      this.service.getAllSubscriptiontype(cv).subscribe((a) => {
        // console.log(a);
        // this.alldebitvoucher = a;
        let debitlist = a
        debitlist.forEach(element => {
       
          this.allvoucherdata.push(element);

        });
        
        this.dr_opening_bal = 0
        

        console.log(this.allvoucherdata, "subscription type");
      });

      setTimeout(() => {
        resolve();
      }, 1000);
    })

    

  }
  calculation() {
    // if (this.allvoucherdata['exp_amt'] === 'cr') {
    //   this.credittotal += parseFloat(this.allvoucherdata['exp_amt']);
    // } else if (this.allvoucherdata['exp_amt'] === 'dr') {
    //   this.debittotal += parseFloat(this.allvoucherdata['exp_amt']);
    // }

    this.allvoucherdata.forEach(element => {
      if (element['voucher'] === 'cr') {
           this.credittotal -= Number(element['exp_amt']);
         } else if (element['voucher'] === 'dr') {
           this.debittotal -= Number(element['exp_amt']);
         }
      
    });
  }

  closeexp(){
    this.dialogRef.close()
  }


}
