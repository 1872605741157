<div>
    <div style="margin-left: 80%; margin-bottom: 2%">
        <button style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary" (click)="saveData()">
            Save
        </button>
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container" id="print-section">
        <div style="margin: 25px">
            <div class="row" style="border: 2px solid black;">
                <div class="col3 text-bold">विवेक साप्ताहिक</div>
                <div class="col3 text-bold">जाहिरात प्रसिद्धीपत्रक</div>
                <div class="col2"></div>
                <div class="col2 text-light-bold">Date : {{ today | date:"dd-MM-yyy"}}</div>
                <div class="col1 text-light-bold" style="border-right: 0px;">Page No : 1</div>
            </div>
            <div class="row" style="border: 2px solid black;border-top: 0px;">
                <div class="col3 text-light-bold">Date From: {{ start_date | date:"dd-MM-yyy" }} To: {{ end_date |
                    date:"dd-MM-yyy" }}</div>
                <div class="col3 text-light-bold">Issue/Grantha Category : {{ issue_category }}</div>
                <!-- <div class="col2 text-light-bold"> </div> -->
                <div class="col2 text-light-bold">Issue Date : {{ issue_date }}</div>
                <div class="col3 text-light-bold" style="border-right: 0px;">Issue Name : {{ issue_name }}</div>
            </div>
            <div class="row" style="border: 2px solid black;border-top: 0px;">
                <div class="col1 text-light-bold">अंक अ.</div>
                <div class="col1 text-light-bold">नोंदणी क्र</div>
                <div class="col2 text-light-bold">जाहिरातदाराचे नाव</div>
                <div class="col2 text-light-bold">ग्राहकाचे नाव</div>
                <div class="col2 text-light-bold">जाहिरात आकार</div>
                <div class="col2 text-light-bold">जाहिरात सूचना</div>
                <div class="col1 text-light-bold" style="border-right: 0px;">पृष्ठ क्र</div>
            </div>
            
            <div >

                <ng-container *ngFor="let data of prasiddhipatrakData;let i=index;">
                    <div  class="row" style="border: 2px solid black;border-top: 0px;">
                        <div class="col4"><span style="font-weight: bold;">जाहिरात आकार: </span>{{data.advt_size}}</div>
                        <div class="col3"></div>
                        <div class="col4"></div>
                    </div>
                    <div class="row" style="border: 2px solid black;border-top: 0px;" *ngFor="let data1 of data.advt_data; let j= index;">
                        <div class="col1" style="text-align: end;">{{data1.advt_count}}</div>
                        <div class="col1">{{data1.booking_no}}</div>
                        <div class="col3">{{data1.name}}</div>
                        <div class="col2"></div>
                        <div class="col2">{{data1.DS_advtsize}}</div>
                        <div class="col1"></div>
                        <div class="col1" style="border-right: 0px;">
                            <span *ngIf="data1['pages_no'] > 0 ">
                                <input type="text" style="width: 60px; height: 30px;" value="{{ data1['pages_no'] }}" (change)="setPanNo($event,data1)">
                            </span>
                            <span *ngIf="data1['pages_no'] == 0">
                                <input type="text" style="width: 60px; height: 30px;" (change)="setPanNo($event,data1)">
                            </span>
                        </div>
                        </div>
                    <div  class="row" style="border: 2px solid black;border-top: 0px;">
                        <div class="col4"><span style="font-weight: bold;">एकूण जाहिरात आकार:{{data.advt_size}} : {{data.total_count}}  </span></div>
                        <div class="col3"></div>
                        <div class="col4"></div>
                    </div>
                </ng-container>
                <!-- *ngFor="let data of item; let i = index; let last = last" -->
                <!-- <div >
                    <div *ngIf="i == 0" class="row" style="border: 2px solid black;border-top: 0px;">
                        <div class="col4 text-light-bold" style="text-align: end;">{{ data['DS_advtsize'] }}</div>
                        <div class="col2"></div>
                        <div class="col2"></div>
                        <div class="col2"></div>
                        <div class="col1" style="border-right: 0px;"></div>
                    </div>
                    <div *ngIf="!last" class="row" style="border: 2px solid black;border-top: 0px;">
                        <div class="col1" style="text-align: end;">{{data['advt_count']}}</div>
                        <div class="col1" style="text-align: center;">{{ data['booking_no'] }}</div>
                        <div class="col2">{{ data['name'] }}</div>
                        <div class="col2"></div>
                        <div class="col2">{{ data['DS_advtsize'] }}</div>
                        <div class="col2"></div>
                        <div class="col1" style="border-right: 0px;">
                            <span *ngIf="data['pages_no'] > 0">
                                <input type="number" value="{{ data['pages_no'] }}" (change)="setPanNo($event,data)">
                            </span>
                            <span *ngIf="data['pages_no'] == 0">
                                <input type="number" (change)="setPanNo($event,data)">
                            </span>
                        </div>
                    </div>
                    <div *ngIf="last" class="row" style="border: 2px solid black;border-top: 0px;">
                        <div class="col1" style="border-right: 0px;"></div>
                        <div class="col1 text-light-bold" style="border-right: 0px; text-align: center;">{{
                            data['total']
                            }}</div>
                        <div class="col3 text-light-bold" style="border-right: 0px;">{{ data['advtsize'] }}</div>
                        <div class="col3" style="border-right: 0px;"></div>
                        <div class="col3" style="border-right: 0px;"></div>
                    </div>
                </div> -->
            </div>
            <div class="row" style="border: 2px solid black;border-top: 0px;">
                <div class="col2 text-light-bold">Total</div>
                <div class="col2 text-light-bold" style="text-align: center;">{{ total_count }}</div>
                <div class="col2"></div>
                <div class="col2"></div>
                <div class="col2"></div>
                <div class="col1" style="border-right: 0px;"></div>
            </div>
            <div class="row" style="border: 2px solid black;border-top: 0px;">
                <div class="col3 height" style="border-right: 0px;"></div>
                <div class="col3 height" style="text-align: center; border-right: 0px;"></div>
                <div class="col3 height" style="border-right: 0px;"></div>
                <div class="col2 height" style="border-right: 0px;"></div>
            </div>
            <div class="row" style="border: 2px solid black;border-top: 0px;">
                <div class="col3">स्वाक्षरी जाहिरात विभाग - लिपिक</div>
                <div class="col3"></div>
                <div class="col2">दिनांक :</div>
                <div class="col3" style="border-right: 0px;">कार्यालय प्रमुख</div>
            </div>
            <div class="row" style="border: 2px solid black;border-top: 0px;">
                <div class="col3" style="border-right: 0px;">संपादकीय विभागाकरिता - लिपिक</div>
                <div class="col3"></div>
                <div class="col2">दिनांक :</div>
                <div class="col3" style="border-right: 0px;">दिनांक :</div>
            </div>
        </div>
        <!-- <div style="margin: 25px">
            <div class="row" style="border: 2px solid black;">
                <div class="col2 text-light-bold">अंक अ.</div>
                <div class="col4 text-light-bold">जाहिरात आकार</div>
                <div class="col2 text-light-bold">संख्या</div>
                <div class="col2 text-light-bold" style="border-right: 0px;">एकूण पानाची संख्या</div>
            </div>
            <div *ngFor="let item of prasiddhipatraksummary; let i = index">
                <div *ngIf="i == multicolorcount" class="row" style="border: 2px solid black;border-top: 0px;">
                    <div class="col2 text-light-bold"></div>
                    <div class="col4 text-light-bold">Colour Pages</div>
                    <div class="col2 text-light-bold"></div>
                    <div class="col2 text-light-bold" style="border-right: 0px;">{{ multicolorpanetotal }}</div>
                </div>
                <div class="row" style="border: 2px solid black;border-top: 0px;">
                    <div class="col2">{{ i + 1 }}</div>
                    <div class="col4">{{ item['advtsize'] }}</div>
                    <div class="col2">{{ item['total'] }}</div>
                    <div class="col2" style="border-right: 0px;">{{ item['totalPane'] }}</div>
                </div>
                <div *ngIf="i >= multicolorcount" class="row" style="border: 2px solid black;border-top: 0px;">
                    <div class="col2"></div>
                    <div class="col4 text-light-bold">B & W Pages</div>
                    <div class="col2"></div>
                    <div class="col2 text-light-bold" style="border-right: 0px;">{{ item['totalPane'] }}</div>
                </div>
            </div>
            <div class="row" style="border: 2px solid black;border-top: 0px;">
                <div class="col2"></div>
                <div class="col4 text-light-bold">Total</div>
                <div class="col2 text-light-bold">{{ total_advt }}</div>
                <div class="col2" style="border-right: 0px;"></div>
            </div>
        </div> -->
    </div>
</div>