import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-subtype',
  templateUrl: './update-subtype.component.html',
  styleUrls: ['./update-subtype.component.scss'],
})
export class UpdateSubtypeComponent implements OnInit {
  subtypeform: FormGroup;
  subtypedata;
  id = 0;

  constructor(
    private fb: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<UpdateSubtypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.subtypeform = fb.group({
      subtype: [''],
      price: [''],
      date: [''],
    });
  }

  ngOnInit(): void {
    this.subtypedata = this.data['item'];
    this.setSubTypeData();
  }

  setSubTypeData() {
    this.id = this.subtypedata['id'];
    this.subtypeform.get('subtype').setValue(this.subtypedata['subtype']);
    this.subtypeform.get('price').setValue(this.subtypedata['price']);
  }

  updatesubtype() {
    let subtype = {
      id: this.id,
      subtype: this.subtypeform.get('subtype').value,
      price: this.subtypeform.get('price').value,
      date: this.subtypeform.get('date').value,
    };
    this.service.updateSubscriptionType(subtype).subscribe((data) => {
      window.alert('Subscriton Type Upadted Successfully...');
    });
    this.dialogRef.close();
  }
}
