<div>
    <div style="margin-left: 80%; margin-bottom: 2%">
        <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printTitle="Label" printSectionId="print-section" ngxPrint>
            print
        </button>
           
        <button mat-dialog-close class="btn noPrint">Close</button>
    </div>
    <div class="container mt-3" id="print-section">
        <div class="row wrapper" *ngFor="let item of FilteredlabelsData" >
            <div class="card" style="page-break-inside: avoid;" *ngIf="item['sub']!=0">
                <div class="firstrow">
                    <div class="left">
                        <img src="../../../assets/img/brand/red.png" alt="" width="100">
                    </div>
                    <div class="right">
                        <span>हिंदुस्थान प्रकाशन संस्था</span>
                        <span>303, वडाळा उद्योग भवन, नायगाव क्राॅस रोड,</span>
                        <span>वडाळा, मुंबई 400 031 दूरध्वनीः 9967570531</span>
                    </div>
                </div>
                <div class="line"></div>
                <div class="maincontent">
                    <div class="vitaran "><span class="">वितरण माध्यम - {{ item['dis_mode'] }} - {{ item['dis_sub_mode'] }}</span>
                        <span class="">अंक दिनांक : {{ankdate}}</span>
                    </div>
                    <div class="prati">
                        <div></div>
                        <div class="right"><span>प्रति,</span>
                            <span class="">वितरण दिनांक : {{ vitarandate }}</span>
                        </div>
                    </div>
                    <div class="main">
                        <div class="pratisankhya">
                            <span style="font-size: 20px;" class="f-w-600">प्रति - </span>
                            <span class="fs-16" style="font-weight: 800;font-size: 20px">{{ item['sub'] }}</span>
                        </div>
                        <div class="name">
                            <span  style="font-weight: 600;">{{ item["dis_rep_name"] }}</span>
                            <span class="fs-16">{{ item["dis_add"] }} {{ item["taluka"] }} </span>
                            <span class="fs-16">{{ item["dis_dist"] }} - {{ item["dis_pin"] }} <br> {{
                                item["dis_state"] }}</span>
                            <span class="fs-16">मोबाईल नं :  {{item["rep_mob_no"] }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- {{ item["title"] }} -->
        <!-- <div class="row">
            <div class="col-3" *ngFor="let item of FilteredlabelsData"
                style="margin-bottom: 4%;margin-top: 1.5%;page-break-inside: avoid;">
                <span style="font-size: 20px"> {{ item["dis_rep_name"] }}</span><br />
                <span style="font-size: 20px">{{ item["dis_add"] }} {{ item["dis_tal"] }}</span><br />
                <span style="font-size: 20px">{{ item["dis_dist"] }}</span>
                <span style="font-size: 20px" *ngIf="item['dis_pin'] != 0"> - {{ item["dis_pin"] }}</span><br
                    *ngIf="item['dis_pin'] != 0" />
                <span style="font-size: 20px" *ngIf="item['dis_state'] != ''">{{ item["dis_state"] }} </span><br />
                <span style="font-size: 20px">{{ vitarandate }}</span><br />
                <span style="font-size: 20px"> {{ item['dis_mode'] }}</span>
                <span style="font-size: 20px"> {{ item['sub'] }}</span>
            </div>
        </div> -->
    </div>
</div>