import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-add-advt-issue-per-price',
  templateUrl: './add-advt-issue-per-price.component.html',
  styleUrls: ['./add-advt-issue-per-price.component.scss'],
})
export class AddAdvtIssuePerPriceComponent implements OnInit {
  advtInvoiceData;
  advtInvoiceList;
  crdtvAmt = 0;
  selectedAmt = 0;
  balanceAmt = 0;
  bal =0;
  amt= 0;
  booking_no: any;

  constructor(
    private dialogRef: MatDialogRef<AddAdvtIssuePerPriceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    
    
    this.advtInvoiceData = this.data['advtInvoice'];
    this.crdtvAmt = isNaN(this.data['crdtAmt']) ? 0 : this.data['crdtAmt'];
    this.selectedAmt = isNaN(this.data['seAmt']) ? 0 : this.data['seAmt'];
    this.balanceAmt = isNaN(this.data['balAmt']) ? 0 : this.data['balAmt'];
    this.bal = isNaN(this.data['bal']) ? 0 : this.data['bal'];
    this.amt = isNaN(this.data['amount']) ? 0 : this.data['amount'];
    this.booking_no = this.data['booking_no'];


    // console.log(this.data);
    if(this.advtInvoiceData.invoice_no!=null){
      let invoiceno=this.advtInvoiceData.invoice_no.split('/')
     let no= invoiceno[0].split("-")
 if (no[0] == 'VSJ') {
      let id = [];
      if (parseInt(this.advtInvoiceData.first_week) != 0) {
        id.push(parseInt(this.advtInvoiceData.first_week));
      } else if (parseInt(this.advtInvoiceData.second_week) != 0) {
        id.push(parseInt(this.advtInvoiceData.second_week));
      } else if (parseInt(this.advtInvoiceData.third_week) != 0) {
        id.push(parseInt(this.advtInvoiceData.third_week));
      } else if (parseInt(this.advtInvoiceData.fourth_week) != 0) {
        id.push(parseInt(this.advtInvoiceData.fourth_week));
      } else if (parseInt(this.advtInvoiceData.fifth_week) != 0) {
        id.push(parseInt(this.advtInvoiceData.fifth_week));
      }
      // console.log(id);
debugger
      // this.service.getAdvtInvoiceDataById(id.toString()).subscribe((res) => {
        

      //   this.advtInvoiceList = res;
      //   console.log( this.advtInvoiceList, "data233");
      // });
      this.service.getAdvtInvoiceDataById(this.advtInvoiceData.advt_name , this.advtInvoiceData.entry_type).subscribe((res) =>{
              this.advtInvoiceList = res;
              console.log( this.advtInvoiceList ,  "new list")

      })

      // this.service.getentrybyrepname(this.advtInvoiceData.advt_name).subscribe((res) => {
      //   // console.log(res);
      //   this.advtInvoiceList = res;
      //   console.log(res , "getentrybyrepnamelist");
      // });
    } 
    }
   else {
      this.service
        .getAdvtInvoiceDataById(this.advtInvoiceData.advt_name , this.advtInvoiceData.entry_type)
        .subscribe((res) => {
          // console.log(res);
          this.advtInvoiceList = res;
          console.log( this.advtInvoiceList, "data233");
        });
      // this.service.getentrybyrepname(this.advtInvoiceData.advt_name).subscribe((res) => {
      //   // console.log(res);
      //   this.advtInvoiceList = res;
      //   console.log(res , "getentrybyrepnamelist");
      // });
    }
  }

  // balanceAmt = 0;
  // selectedAmt = 0;
  value = 0;
  newvalue=0;
  calcamount(event, book) {
    
    
    let val = event.target.value;
    
    if(this.newvalue==0){
      this.newvalue=val;
    }else{
      this.newvalue += val
    }
    this.newvalue=val;
    // this.selectedAmt = 0;
    // console.log({ book });
    if(book.rcvd_amt == null){
      book.rcvd_amt = 0
    }
    let remaining_amt =
      parseFloat(book['total_amount']) -
      (parseFloat(book['rcvd_amt']) + parseFloat(val));
    console.log(remaining_amt);

    this.balanceAmt = 0;
    for (
      let index = 0;
      index < Object.keys(this.advtInvoiceList).length;
      index++
    ) {
      this.advtInvoiceList[index]['address']=this.advtInvoiceList[index]['address'];
      if (this.advtInvoiceList[index]['id'] == book['id']) {
        this.advtInvoiceList[index]['rcvd_amt'] =
          parseFloat(this.advtInvoiceList[index]['rcvd_amt']) +  parseFloat(val);
        this.value = this.value + parseFloat(val);
        this.advtInvoiceList[index]['remaining_amount'] = remaining_amt;
        this.advtInvoiceData.remaining_amt = remaining_amt;
        
      this.selectedAmt =
      this.selectedAmt + parseFloat(this.advtInvoiceList[index]['rcvd_amt']);
    this.balanceAmt = this.crdtvAmt - this.selectedAmt;
      }
    }
     console.log({ book } , "val");
  }

  save() {
    
    
    // console.log(this.value);
    this.advtInvoiceData.advtInvoiceList = this.advtInvoiceList;
    // console.log(this.advtInvoiceData);
    

    this.dialogRef.close({
      advtInvoiceData: this.advtInvoiceData,
      selecetdAmt: this.selectedAmt,
      balanceAmt: this.balanceAmt,
      address:this.advtInvoiceList[0].address,
      mobille:this.advtInvoiceList[0].mobille,
      newvalue:this.newvalue,
      main_booking_no:this.advtInvoiceList[0].main_booking_no
    });
    console.log(this.dialogRef);
    // this.dialogRef.close();
  }
}
