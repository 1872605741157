import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-address-labels-mobile-no',
  templateUrl: './address-labels-mobile-no.component.html',
  styleUrls: ['./address-labels-mobile-no.component.scss'],
})
export class AddressLabelsMobileNoComponent implements OnInit {
  labelsData = [];
  tempdata = [];
  data1 = [];
  today = new Date();
  iscustomer: any;
  shortName = {
    वार्षिक: 'वा.',
    त्रैवार्षिक: 'त्रै.',
    पंचवार्षिक: 'पंच.',
    लोकनेता_गंथयात्री_वार्षिक: 'लो.गं.वा.',
    लोकमान्य_ग्रंथयात्री_वार्षिक_वर्गणी: 'लो.ग्रं.वा.व.',
    हिंदुत्व_ग्रंथयात्री: 'हिं.ग्रं.',
    हिंदुत्व_2: 'हिं.2.',
    रामसेवक_ग्रंथयात्री: 'रा.ग्रं.',
  };

  constructor(
    private dialogref: MatDialogRef<AddressLabelsMobileNoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.labelsData = this.data['addresslabels'];
    console.log(this.data1,"data1");
    this.iscustomer = this.data['iscustomer'];


    this.tempdata = this.uniqueid(this.labelsData, (a) => a['district']);
    // console.log(this.tempdata);

    this.sortDataDistrictwise();
  }

  // sortDataDistrictwise() {
  //   for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
  //     let data = this.labelsData.filter(
  //       (a) => a['district'] == this.tempdata[index]['district']
  //     );

  //     // this.tempdata.push({ index: data });
  //     this.data1.push(data);
  //   }
  //   // console.log(this.data1);
  //   // console.log(this.tempdata);
  // }

  sortDataDistrictwise() {
    this.labelsData.sort((a, b) => Number(a.pincode) - Number(b.pincode));
    // this.tempdata.sort((a, b) => a.name.localeCompare(b.name, ['en', 'mr']));
    for (let index = 0; index < Object.keys(this.tempdata).length; index++) {
      let data = this.labelsData.filter(
        (a) => a['district'] == this.tempdata[index]['district']
      );

      data.map((a) => {
        a.shortname = this.shortName[a.Subscription_Type.split(' ').join('_')];
      });

      // this.tempdata.push({ index: data });

      // data.sort((a, b) => {
      //   if (a.name < b.name) {
      //     return -1;
      //   } else if (a.name > b.name) {
      //     return 1;
      //   } else {
      //     return 0;
      //   }
      // });
     
      this.data1.push(data);

     
    }

    let data=[]
    this.data1.forEach(element => {
      element.forEach(data2 => {
        data.push(data2)
      });
      
    });
    this.data1=data
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }
}
